import config from '@/common/config';
import store from '@/store/state';
import ApiService from '@/api/index';

import { Auth0Client } from '@auth0/auth0-spa-js';

class Auth0Service {
  /* TODO:DEBUG  ctor is being called 2x - not sure why? */

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.auth0 = new Auth0Client({
      domain: config.auth.AUTH0_DOMAIN, // e.g., auth.montra.io
      clientId: config.auth.AUTH0_CLIENT_ID, // e.g., i473732832832cfgajHYEUqiqwq
      authorizationParams: {
        redirect_uri: `${window.location.origin}/callback`, // e.g., http://localhost:8080/callback
        audience: config.auth.AUTH0_API_AUDIENCE, // e.g., https://meetupapi.com
        response_type: 'token id_token', // at a min, need a token responseType
        scope: 'openid profile email ',
      },
      leeway: 30, // A value in seconds; leeway to allow for clock skew with regard to ID Token expiration times.
    });
  }

  /** Callback handler from auth0.authorize() method call
   * @returns {Promise}
   */
  async handleAuthentication() {
    // const isAuthenticated = await this.auth0.isAuthenticated();
    // if (isAuthenticated) {
    //   console.log('Already Authenticated!');
    // } else {
    //   console.log('Not Authenticated!');
    //   await this.auth0.handleRedirectCallback();
    // }

    let user = null;
    const auth = await this.auth0.getTokenSilently({
      detailedResponse: true,
    });
    // console.log(auth);
    if (auth) {
      user = await this.processToken(auth);
      // console.log(user);
    }
    if (user) {
      let reqPath = store.getters['account/requestedPath'];
      console.log(reqPath);
      if (!reqPath || (reqPath && reqPath.length == 1)) {
        reqPath = store.getters['account/defaultHref'];
      }
      store.dispatch('account/resetRequestedPath', null); // reset path
      return reqPath;
    } else {
      // console.log('user not found?');
      return '/';
    }
  }
  async refreshToken() {
    console.log('Refresh Token using auth0.authorize?');
    this.logout();
    // this.auth0.authorize();
  }
  async processToken(auth) {
    // console.log('processToken');
    // returns Montra user
    let user = null; // this is what we'll return
    // Vue.$log.debug('newSSOToken is set: persist & capture/map Montra user');
    try {
      // We add the access token as an authorization header for our Axios requests to our API
      store.commit('account/SET_USER_AUTH', auth, true);
      user = await this.loadUser();
      return user;
    } catch (err) {
      // console.log(err);
    }
  }

  async loadUser() {
    console.log('loadUser');
    const user = await ApiService.apiCore.get('auth/user', { params: { source: 'auth0' } }).then((authResp) => {
      // console.log(authResp);
      if (authResp && authResp.status === 200) {
        return authResp.data; // echo what api received (JWT) or decrypted idToken
      } else {
        throw new Error(`ValidateLogin ${authResp}`);
      }
    });

    await ApiService.apiCore.get(`/core/permission/user/${user.id}?$expand=[userRoles,permissions]`).then((resp) => {
      if (resp && resp.status === 200) {
        if (resp.data) {
          const { permissions, userRoles } = resp.data.Results;
          user.permissionsString = permissions?.map((p) => p.permissionCode).toString();
          if (user.permissions != permissions) {
            user.permissions = permissions;
          }
          if (user.userRoles != userRoles) {
            user.userRoles = userRoles;
          }
        }
      }
    });

    user.picture = user.picture || 'https://placeimg.com/250/250/people';
    // if (state.user.length > 1) {
    //     return state.user[0];
    // } else {
    //     store.commit("account/SET_USER", user);
    //     return user;
    // }
    store.commit('account/SET_USER', user);
    return user;
  }
  /**
   * Typically called from Vuex store "account/loginUser" via router beforeEach validation
   */
  async login() {
    await this.auth0.loginWithRedirect();
  }

  /**
   * Typically called from Vuex store "account/logoutUser" from user profile menue
   */
  async logout() {
    // auth0 api change - defined return to uri
    await this.auth0.logout({
      logoutParams: {
        returnTo: `${window.location.origin}`
      }
    });
  }
}

export { Auth0Service };
