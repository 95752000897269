<template>
  <v-app :key="reload" :class="{ isNotProd: isNotProd }">
    <div v-if="isAuthenticated">
      <app-drawer class="app--drawer" />
      <app-toolbar
        :refresh="refresh"
        class="app--toolbar"
        style="z-index: 5"
        :class="{ isImpersonated: isImpersonated }"
      />
    </div>
    <v-main id="v-main" :class="{ isImpersonated: isImpersonated }">
      <div>
        <toaster />
        <opt-in />
        <cookie-consent />
        <router-view />
      </div>
      <v-dialog v-model="hashChanged" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="main-header">Update Available</span>
            <div class="flex-grow-1" />
            <v-btn variant="text" color="base" @click="hashChanged = false">
              <v-icon>fal fa-times</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="8">
                <p>
                  An update is available. Please save all current work and click update below. You can also accept these
                  updates by refreshing your browser at any time.
                </p>
                <h4 class="text-orange">Not updating may result in errors.</h4>
              </v-col>
              <v-col cols="4">
                <v-img
                  cover
                  width="200"
                  lazy-src="/static/img/monty_pleased.png"
                  src="/static/img/monty_pleased.png"
                  class="text-center d-inline-block"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn variant="text" @click="hashChanged = false"> Cancel </v-btn>
            <v-btn variant="text" color="info" @click="reloadApp()"> Update </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
    <v-bottom-navigation v-if="isImpersonated" bg-color="red" height="30">
      <impersonater :reload="reload" />
    </v-bottom-navigation>
    <app-cart />
    <app-help />
    <code ref="verInfo" style="visibility: hidden" />
  </v-app>
</template>

<script>
import Toaster from '@/components/Toaster.vue';
import Impersonater from '@/components/Impersonater.vue';
import CookieConsent from '@/components/CookieConsent.vue';
import OptIn from '@/components/OptIn.vue';
import AppDrawer from '@/components/navtoolbar/AppDrawer.vue';
import AppToolbar from '@/components/navtoolbar/AppToolbar.vue';
import AppCart from '@/components/navtoolbar/AppCart.vue';
import AppHelp from '@/components/navtoolbar/AppHelp.vue';
import store from '@/store/state.js';
import config from '@/common/config.js';
import { refreshPageMixin } from '@/plugins/refresh-page.mixin.js';

// Fontsource downloaded Roboto fonts
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

import { AuthenticationService } from '@/services/authentication.service.js';
const authService = new AuthenticationService();

export default {
  name: 'App',
  components: {
    AppDrawer,
    AppToolbar,
    AppCart,
    AppHelp,
    CookieConsent,
    Toaster,
    Impersonater,
    OptIn,
  },
  mixins: [refreshPageMixin],
  data: () => ({
    polling: null,
    expanded: true,
    rightDrawer: false,
    snackbar: {
      show: false,
      text: '',
      color: '',
    },
    reload: 0,
    history: null,
  }),
  computed: {
    isNotProd() {
      return process.env.NODE_ENV !== 'production' && !window.location.hostname.includes('localhost');
    },
    isImpersonated() {
      return store.getters['account/isImpersonated'];
    },
    impUser() {
      return store.getters['account/loggedInUser'];
    },
    user() {
      return store.getters['account/user'];
    },
    isAuthenticated() {
      return store.getters['account/isAuthenticated'](); // special - call getter as function - avoids cache issues;
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.name !== this.history) {
          this.history = this.$route.name;
        } else {
          this.reload++;
        }
      },
      deep: true,
    },
    'user.id': {
      handler: function () {
        this.reload++;
      },
    },
  },
  async mounted() {
    // console.log('App.vue mounted event started');
    this.pollData();
    store.dispatch('account/syncUserPermissions'); // if user refreshes browser, ensure we capture user permissions
    this.initVersionCheck();

    const id = this.isImpersonated ? this.user.id + ' [' + this.impUser.id + ']' : this.user.id;
    const name = this.isImpersonated ? this.user.name + ' [' + this.impUser.name + ']' : this.user.name;
    const email = this.isImpersonated ? this.user.email + ' [' + this.impUser.email + ']' : this.user.email;
    const tenant = this.isImpersonated
      ? this.user?.tenant?.name + ' [' + this.impUser?.tenant?.name + ']'
      : this.user?.tenant?.name;

    // eslint-disable-next-line
    // if (typeof playerzero === 'object') {
    //   // eslint-disable-next-line
    //   playerzero.identify(this.user.nickname, {
    //     name: name,
    //     email: email,
    //     group: tenant,
    //   });
    // }

    // eslint-disable-next-line
    gtag('config', 'G-2PYJB22XK7', {
      user_id: id,
    });
    // eslint-disable-next-line
    gtag('set', 'user_properties', {
      user_name: name,
    });
    // eslint-disable-next-line
    gtag('set', 'user_properties', {
      user_email: email,
    });
    // eslint-disable-next-line
    gtag('set', 'user_properties', {
      user_tenant: tenant,
    });
  },
  async created() {
    // window.addEventListener('keypress', this.onKeyPress);
    // console.log('App.vue created event started');
    // let isPending = store.getters["account/pendingLogin"];
    // console.log(`PendingLogin: ${isPending}`);

    // If we are using msal-browser redirect mode - must eval handleRedirectPromise on page load to keep the o365 events in sync
    if (config.sso.loginMethod == AuthenticationService.signInType.redirect) {
      await authService.authO365.init();
      await authService.authO365.handleRedirectPromise().then(async (resp) => {
        if (resp) {
          const target = await authService.authO365.handleResponse(resp); // will return href loc to navigate to (named routes don't always work)
          // console.log(target);
          if (target) {
            location.pathname = target;
          } else {
            store.dispatch('account/pendingLogin', false);
          }
        }
      });
    }
    // console.log('App.vue created event completed')
  },
  beforeUnmount() {
    // window.removeEventListener('keypress', this.onKeyPress);
    clearInterval(this.polling);
  },
  methods: {
    refresh() {
      // console.log('Refreshing page');
      this.reload++;
    },
    refreshStats() {
      // console.log('Refreshing stats');
      store.dispatch('account/syncUserPermissions');
    },
    pollData() {
      // console.log('Polling for user stats');
      this.polling = setInterval(this.refreshStats, import.meta.POLLING_INTERVAL || 300000); // if not set in .env, def. to 5 min
    },
  },
};
</script>
<style lang="scss">
@import './assets/style/global.scss';
@import './assets/style/dark.scss';
@import './assets/style/light.scss';
</style>