export default [
  { code: '11', description: 'UPS Standard' },
  { code: '08', description: 'UPS Worldwide Expedited' },
  { code: '07', description: 'UPS Worldwide Express' },
  { code: '54', description: 'UPS Worldwide Express Plus' },
  { code: '65', description: 'UPS Worldwide Saver' },
  { code: '72', description: 'UPS® Worldwide Economy DDP' },
  { code: '17', description: 'UPS® Worldwide Economy DDU' },
  { code: '00', description: 'Hand (Pickup Montra Office)' },
  { code: 'FEDEX_INTERNATIONAL_PRIORITY_EXPRESS', description: 'FedEx International Priority® Express' },
  { code: 'INTERNATIONAL_FIRST', description: 'FedEx International First®' },
  { code: 'FEDEX_INTERNATIONAL_PRIORITY', description: 'FedEx International Priority®' },
  { code: 'INTERNATIONAL_ECONOMY', description: 'FedEx International Economy®' },
  { code: 'FEDEX_GROUND', description: 'FedEx International Ground®' },
  { code: 'SMART_POST', description: 'FedEx Ground® Economy' },
  { code: 'PRIORITY_OVERNIGHT', description: 'FedEx Priority Overnight®' },
  { code: 'STANDARD_OVERNIGHT', description: 'FedEx Standard Overnight®(Hawaii outbound only)' },
  { code: 'FEDEX_2_DAY', description: 'FedEx 2Day® (Except Intra-Hawaii)' },
  { code: 'FEDEX_2_DAY_AM', description: 'FedEx 2Day® AM (Hawaii outbound only)' },
  { code: 'FEDEX_EXPRESS_SAVER', description: 'FedEx Express Saver® (Except Alaska and Hawaii)' },
];
