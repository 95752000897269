import config from '@/common/config';
import store from '@/store/state';
import { PublicClientApplication } from '@azure/msal-browser';
import { differenceInSeconds } from 'date-fns';
import ApiService from '@/api/index';
import { LogLevel, ProtocolMode } from '@azure/msal-common';

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/0d91217644/lib/msal-browser/src/config/Configuration.ts
const msalConfig = {
  /**
   * Use this to configure the auth options in the Configuration object
   *
   * - clientId                    - Client ID of your app registered with our Application registration portal : https://portal.azure.com/#blade/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/RegisteredAppsPreview in Microsoft Identity Platform
   * - authority                   - You can configure a specific authority, defaults to " " or "https://login.microsoftonline.com/common"
   * - knownAuthorities            - An array of URIs that are known to be valid. Used in B2C scenarios.
   * - cloudDiscoveryMetadata      - A string containing the cloud discovery response. Used in AAD scenarios.
   * - redirectUri                - The redirect URI where authentication responses can be received by your application. It must exactly match one of the redirect URIs registered in the Azure portal.
   * - postLogoutRedirectUri      - The redirect URI where the window navigates after a successful logout.
   * - navigateToLoginRequestUrl  - Boolean indicating whether to navigate to the original request URL after the auth server navigates to the redirect URL.
   * - clientCapabilities         - Array of capabilities which will be added to the claims.access_token.xms_cc request property on every network request.
   * - protocolMode               - Enum that represents the protocol that msal follows. Used for configuring proper endpoints.
   */
  auth: {
    clientId: config.sso.CLIENT_ID,
    authority: config.sso.AUTHORITY,
    redirectUri: window.location.origin + config.sso.REDIRECT_URI,
    postLogoutRedirectUri: window.location.origin + '/logout',
    // A matching scope has to be configured in Azure tenant
    // property is not part of msal.configuration - but is referenced by internal code as msalInstance.config.auth.scopes
    scopes: config.sso.SCOPES, // offline_access will create a long-lived token
    navigateToLoginRequestUrl: false, // If the redirectUri is the same as the original request location, this flag should be set to false.
    protocolMode: ProtocolMode.AAD, // If "AAD", will function on the OIDC-compliant AAD v2 endpoints;
  },
  /**
   * Use this to configure the below cache configuration options:
   *
   * - cacheLocation            - Used to specify the cacheLocation user wants to set. Valid values are "localStorage" and "sessionStorage"
   * - storeAuthStateInCookie   - If set, MSAL stores the auth request state required for validation of the auth flows in the browser cookies. By default this flag is set to false.
   * - secureCookies            - If set, MSAL sets the "Secure" flag on cookies so they can only be sent over HTTPS. By default this flag is set to false.
   */
  cache: {
    cacheLocation: 'localStorage',
  },
  /**
   * Library Specific Options
   *
   * - tokenRenewalOffsetSeconds    - Sets the window of offset needed to renew the token before expiry
   * - loggerOptions                - Used to initialize the Logger object (See ClientConfiguration.ts)
   * - networkClient                - Network interface implementation
   * - windowHashTimeout            - Sets the timeout for waiting for a response hash in a popup. Will take precedence over loadFrameTimeout if both are set.
   * - iframeHashTimeout            - Sets the timeout for waiting for a response hash in an iframe. Will take precedence over loadFrameTimeout if both are set.
   * - loadFrameTimeout             - Sets the timeout for waiting for a response hash in an iframe or popup
   * - navigateFrameWait            - Maximum time the library should wait for a frame to load
   * - redirectNavigationTimeout    - Time to wait for redirection to occur before resolving promise
   * - asyncPopups                  - Sets whether popups are opened asynchronously. By default, this flag is set to false. When set to false, blank popups are opened before anything else happens. When set to true, popups are opened when making the network request.
   * - allowRedirectInIframe        - Flag to enable redirect operations when the app is rendered in an iframe (to support scenarios such as embedded B2C login).
   */
  system: {
    asyncPopups: true,

    allowRedirectInIframe: true,
    loggerOptions: {
      logLevel: LogLevel.Verbose,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warning(message);
            return;
        }
      },
    },
  },
  //mode: 'redirect' // other options: ?
};

/* const silentRequest = {
    loginHint: "jstott@montra.io"
}; */

const loginRequest = {
  prompt: 'select_account',
  scopes: config.sso.SCOPES,
};

/* const tokenRequest = {
    scopes: ["Mail.Read", "openid", "profile"],
    forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
}; */
class AuthO365Service {
  static signInType = {
    popup: 'loginPopup',
    redirect: 'loginRedirect',
  };
  signInMethod = config.sso.loginMethod;
  static watchDogTimerId = null;

  constructor(signInMethod) {
    this.msal = new PublicClientApplication(msalConfig);
    this.signInMethod = signInMethod || AuthO365Service.signInType.redirect;
  }

  async init() {
    await this.msal.initialize();
  }

  async handleRedirectPromise() {
    const resp = await this.msal.handleRedirectPromise();
    return resp;
  }

  cancelWatchdog() {
    if (AuthO365Service.watchDogTimerId) {
      clearTimeout(AuthO365Service.watchDogTimerId);
    }
  }

  setWatchdog() {
    this.cancelWatchdog();
    AuthO365Service.watchDogTimerId = setTimeout(
      async function () {
        store.dispatch('account/pendingLogin', false); // we've timed out, effectively no longer waiting to login
        store.dispatch('alerts/addAlert', {
          alertText: 'SSO Login TimedOut',
          alertType: 'error',
          delay: 3500,
        });
        store.dispatch('alerts/addAlert', {
          alertText: 'Redirecting - Standby',
          alertType: 'warning',
          delay: 2000,
        });
        store.dispatch;
        await new Promise((resolve) => setTimeout(resolve, 1000)); // 1 sec delay before proceeding
        location.pathname = '/'; // redirect back to the root & let our processes take over
      }.bind(this),
      6000,
    );
  }

  async redirectToken() {
    let newSSOToken = null;
    // console.log('autho365:redirectToken: no newSSOToken - requesting login pop-up to get it');
    newSSOToken = await this.msal.loginRedirect(loginRequest);
    return newSSOToken;
  }

  /**
   * Called from either signInRedirect or signInPopUp - when the user has an existing token
   * TODO NEW: or can be called from refreshToken - when we need to get a new token
   * @param {*} ssoToken
   * @returns
   */
  async refreshToken(ssoToken) {
    // console.log('Old Token', ssoToken)
    let newSSOToken = null;
    if (ssoToken && ssoToken.account) {
      /***
       * Will either silently acquire access token: msal.acquireTokenSilent
       * OR
       * Redirect  user's browser window: msal.acquireTokenRedirect
       *
       * limitedScopes is an attempt to prevent msal from getting a brand new
       * token on acquireTokenSilent due to the presence of openid and profile
       * scopes  So far I have only found this mentioned in github issue tickets.
       ***/
      const limitedScopes = config.sso.SCOPES.filter((scope) => ['profile', 'openid'].indexOf(scope) === -1);
      newSSOToken = await this.msal
        .acquireTokenSilent({
          account: ssoToken.account,
          scopes: limitedScopes,
          redirectUri: window.location.origin + config.sso.REDIRECT_URI,
          forceRefresh: true,
          extraQueryParameters: {
            login_hint: ssoToken.account.idTokenClaims.preferred_username,
            domain_hint: 'organizations',
          },
        })
        .then((resp) => {
          // console.log('autho365:refreshToken', resp);
          // getSilentToken does not return original account details.
          resp.account = ssoToken.account;
          return resp;
        })
        .catch((err) => {
          console.log('autho365:refreshToken msalInstance.getSilentToken error - this is not expected', err);
          store.dispatch('account/clearUser');
          location.pathname = '/'; // redirect back to the root & let our processes take over
        });
    } else {
      // console.log('autho365:refreshToken no ssoToken or account  - ??');
    }
    return newSSOToken;
  }

  async loadUser() {
    const impersonated = store.getters['account/isImpersonated'] || false;
    if (!impersonated) {
      const user = await ApiService.apiCore.get('auth/user', { params: { source: 'o365' } }).then((authResp) => {
        // console.log(authResp, authResp.status)
        if (authResp && authResp.status === 200) {
          return authResp.data; // echo what api received (JWT) or decrypted idToken
        } else {
          // console.log('Auth Error')
          throw new Error(`ValidateLogin ${authResp}`);
        }
      });

      await ApiService.apiCore.get(`/core/permission/user/${user.id}?$expand=[userRoles,permissions]`).then((resp) => {
        if (resp && resp.status === 200) {
          if (resp.data) {
            const { permissions, userRoles } = resp.data.Results;
            user.permissionsString = permissions?.map((p) => p.permissionCode).toString();
            if (user.permissions != permissions) {
              user.permissions = permissions;
            }
            if (user.userRoles != userRoles) {
              user.userRoles = userRoles;
            }
          }
        }
      });

      user.picture = user.picture || 'https://placeimg.com/250/250/people';
      // if (state.user.length > 1) {
      //     return state.user[0];
      // } else {
      //     store.commit("account/SET_USER", user);
      //     return user;
      // }
      store.commit('account/SET_USER', user);
      return user;
    }
  }

  /************************************************************************************************
   *   New Methods
   */

  /**
   *
   * @param {*} newSSOToken
   * accessToken → String Access token received as part of the response.
   * account → AccountInfo? An account object representation of the currently signed-in user
   * authority → String The authority that the token was retrieved from.
   * cloudGraphHostName → String The AAD graph host.
   * expiresOn - Date representing relative expiration of access token.
   * extExpiresOn → DateTime? Date representing extended relative expiration of access token in case of server outage.
   * familyId → String? Family ID identifier, usually only used for refresh tokens.
   * fromCache → bool Boolean denoting whether token came from cache.
   * hashCode → int The hash code for this object.
   * idToken → String ID token received as part of the response.
   * idTokenClaims → Map<String, dynamic> MSAL-relevant ID token claims.
   * msGraphHost → String? The Microsoft Graph host.
   * runtimeType → Type A representation of the runtime type of the object.
   * scopes → List<String> Scopes that are validated for the respective token.
   * state → String? Value passed in by user in request.
   * tenantId → String tid claim from ID token.
   * tokenType → String read-only
   * uniqueId → String oid or sub claim from ID token.
   * @returns
   */
  async processToken(newSSOToken) {
    // console.log('processToken', newSSOToken);
    // returns Montra user
    let user = null; // this is what we'll return
    // console.log('autho365:processToken newSSOToken is set: persist & capture/map Montra user');
    try {
      this.cancelWatchdog();
      // We add the access token as an authorization header for our Axios requests to our API
      store.commit('account/SET_SSO_TOKEN', newSSOToken);
      // store.commit('account/SET_AUTHENTICATED', true);

      const userAuth = {
        accessToken: newSSOToken.idToken, // idToken is the JWT we need, not accessToken
        idToken: newSSOToken.idToken,
        refreshToken: null,
        state: newSSOToken.state,
        expiresIn: differenceInSeconds(new Date(Date.parse(newSSOToken.expiresOn)), new Date()), // laterDate, earlierDate
        tokenType: newSSOToken.tokenType,
        scope: newSSOToken.scopes.join(' '),
        expiresAt: newSSOToken.expiresOn,
        account: newSSOToken.account,
        tenantId: newSSOToken.tenantId,
      };
      store.commit('account/SET_USER_AUTH', userAuth);
      // console.log('user auth step', userAuth);
      this.msal.setActiveAccount(newSSOToken.account);
      user = await this.loadUser();
    } catch (err) {
      // console.log('processToken failed', err);
      console.log(err);
      store.dispatch('account/clearUser');
      location.pathname = '/unexpected';
    }
    return user;
  }

  async processUpdateToken(newSSOToken) {
    let userAuth = null; // this is what we'll return
    // console.log('autho365:processUpdateToken newSSOToken refreshed');
    try {
      this.cancelWatchdog();
      // We add the access token as an authorization header for our Axios requests to our API
      // console.log('processUpdateToken newSSOToken is set: persist & capture/map Montra user');
      store.commit('account/SET_SSO_TOKEN', newSSOToken);
      // store.commit('account/SET_AUTHENTICATED', true);

      userAuth = {
        accessToken: newSSOToken.idToken, // idToken is the JWT we need, not accessToken
        idToken: newSSOToken.idToken,
        refreshToken: null,
        state: newSSOToken.state,
        expiresIn: differenceInSeconds(new Date(Date.parse(newSSOToken.expiresOn)), new Date()), // laterDate, earlierDate
        tokenType: newSSOToken.tokenType,
        scope: newSSOToken.scopes.join(' '),
        expiresAt: newSSOToken.expiresOn,
        account: newSSOToken.account,
        tenantId: newSSOToken.tenantId,
      };
      store.commit('account/SET_USER_AUTH', userAuth);
      this.msal.setActiveAccount(newSSOToken.account);
      userAuth = await this.loadUser();
    } catch (err) {
      // maybe dont log someone out if their auth didn't update correctly?
      // console.log(err);
      // store.dispatch("account/clearUser");
      // location.pathname = "/unexpected";
    }
    return userAuth;
  }

  async signIn() {
    let user = null; // this is what we'll return
    const ssoToken = store.getters['account/ssoToken'];
    try {
      if (this.signInMethod === 'loginPopup') {
        user = await this.signInPopUp(ssoToken);
      } else {
        store.dispatch('account/pendingLogin', true);
        user = await this.signInRedirect(ssoToken);
      }
    } catch (err) {
      console.log(err);
    }
    // User will never be set if we had to redirect use to Azure AD auth pages
    // If we get this far - capture all the deets and redirect user to A) where they were or B) toplevel menu item they have access to.
    if (user) {
      // console.log('autho365:signIn user has been returned from signIn(), account/pendingLogin set to false');
      store.dispatch('account/pendingLogin', false);
      let reqPath = store.getters['account/requestedPath'];
      // console.log(reqPath)
      if (!reqPath || (reqPath && reqPath.length == 1)) {
        reqPath = store.getters['account/defaultHref'];
      }
      store.dispatch('account/resetRequestedPath', null); // reset path
      location.pathname = reqPath;
    }
    return user;
  }

  /**
   * (Prior method to 5/20/2022)
   * Method called from store.logoutUser => authentication.service == AAD method
   * This method will sign user out of AAD (may not be totally expected)
   */
  async signOutFromAAD() {
    const currentAccounts = this.msal.getAllAccounts();
    const accountId = currentAccounts[0].homeAccountId;
    const logoutRequest = {
      account: this.msal.getAccountByHomeId(accountId),
    };
    this.msal.logoutRedirect(logoutRequest);
  }

  /**
   * (Active since 5/20/2022 - do not log user out from AAD, only from VIA)
   * Method called from store.logoutUser => authentication.service == AAD method
   * This method assumes the store authentication properties have been cleared, and will
   * simply redirect user to home page (auth page) w/o touching AAD
   */
  async signOut() {
    store.dispatch('account/redirectToPath', '/');
  }

  /**
   * If ssoToken is present, attempt to refresh the token and return Montra User
   * If unsuccessful, a popup modal will be provided for user to enter credentials
   * @param {*} ssoToken
   * @returns Montra User if token is successfully obtained
   */
  async signInPopUp(ssoToken) {
    let user = null;
    let newSSOToken = null;
    if (ssoToken && ssoToken.account) {
      newSSOToken = await this.refreshToken(ssoToken);
    }
    if (!newSSOToken) {
      newSSOToken = await this.getTokenPopUp();
    }
    if (newSSOToken) {
      user = await this.processToken(newSSOToken);
    }
    return user;
  }

  /**
   * If ssoToken is present, attempt to refresh the token and return Montra User
   * If unsuccessful, a redirect page will be provided for user to enter credentials
   * @param {*} ssoToken
   * @returns Montra User if token is successfully obtained
   */
  async signInRedirect(ssoToken) {
    let user = null;
    let newSSOToken = null;
    if (ssoToken && ssoToken.account) {
      newSSOToken = await this.refreshToken(ssoToken);
      if (newSSOToken) {
        user = await this.processToken(newSSOToken);
      }
    } else {
      await this.getTokenRedirect(); // will never return newSSOToken - page will be redirected for user login
    }
    return user;
  }

  /**
   * Specifically will use a PopUp login page for User credential input
   * @returns Token if successful
   */
  async getTokenPopUp() {
    let newSSOToken = null;
    // console.log('autho365:getTokenPopUp no newSSOToken - requesting login pop-up to get it');
    newSSOToken = await this.msal.loginPopup(loginRequest);
    return newSSOToken;
  }

  /**
   * Specifically will use a redirect login page for User credential input
   * @returns Token if successful
   */
  async getTokenRedirect() {
    const newSSOToken = null;
    // console.log('autho365:getTokenRedirect no newSSOToken - requesting login pop-up to get it');
    //await this.msal.acquireTokenRedirect({...loginRequest, redirectStartPage: window.location.href});
    await this.msal.loginRedirect({ ...loginRequest }).catch((err) => {
      console.log(err);
    });
    return newSSOToken;
  }

  /* This will be called from a Page onload event (e.g. created)
    Handles the redirect response from msal-browser redirect - ultimately return a target route (if successful) so we nav the user to the correct page.
    It honestly doesn't matter what route path the user was on - they were kicked out & had to auth through Azure AD.
    Look to see what the top-level menu item is, and navigate there
    */
  async handleResponse(resp) {
    this.cancelWatchdog();
    if (resp !== null) {
      store.dispatch('account/pendingLogin', false); // only reset pendingLogin after we've eval'd user
      await this.processToken(resp);
      /**  new */
      const hrefTarget = store.getters['account/defaultHref'];
      return hrefTarget;
    }
    return null;
  }
}

export { AuthO365Service };
