// import * as Vue from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes/index';
import NProgress from 'nprogress';
//import 'nprogress/nprogress.css'; see index.html and public/static/css/nprogress.css
import store from '@/store/state';
import { evalLevel } from '@/api/core/meta/userPermission.meta';

const authService = store.getters['account/authService'];
// grab from store vs instantiating new object
const isImpersonated = store.getters['account/isImpersonated'];

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_SITE_PATH),
  routes,
});

// router guards
router.beforeEach(async (to, from, next) => {
  NProgress.start();

  //ensure we grab user from store before each route generates:
  const user = store.getters['account/user'];
  const isPublic = to.matched.some((record) => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut);

  try {
    const isAuthenticated = await authService.isAuthenticationValid(to.fullPath);

    if (isImpersonated && !isAuthenticated) {
      // Vue.$log.debug('router:beforeEach clear the impersonation before relogging in')
      store.dispatch('account/clearImpersonation', '');
    }

    /**
     * Invoke
     */
    if (!isPublic && !isAuthenticated) {
      // Vue.$log.debug('router:beforeEach user is not authenticated, about to have to them login')
      const idpUser = store.getters['account/ssoToken'];
      const type = idpUser ? 'sso' : null;
      const targetPath = to.path;
      // Vue.$log.debug(`router:beforEach saving requested path: ${targetPath}`);
      store.dispatch('account/saveRedirectPath', targetPath);
      await authService.loginUser(type);
      return next(false);
    }

    if (isAuthenticated && onlyWhenLoggedOut) {
      return next('/dashboard/main');
    }
  } catch (err) {
    console.error(err.message);
  }

  ///  evalLevel(user, to) checks to see if the user has the feature OR role listed within the routes meta.featureOrRoles
  if (!evalLevel(user, to)) {
    // console.warn('USER DOES NOT HAVE ACCESS TO THIS PAGE');
    // Vue.$log.debug(`router:beforEach user does not have access to: ${to.path}`);
    return next('/access-denied');
  }

  next();
  return;
});

router.afterEach((to) => {
  // change page title after you've changed routes
  document.title = to.meta.title ? `Montra - ${to.meta.title}` : 'Montra';
  //  TODO: add some analytics codes here if we want
  NProgress.done();
});

export default router;
