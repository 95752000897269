import { AuthenticationService } from '@/services/authentication.service';
// import axios from "axios";
import util from '@/util';
//import ApiService from "@/api/index";
import ApiService from '@/api/index';

import {hasPermission} from '@/common/permission.mapper.js';

const anonUser = {
  name: 'Unknown',
  nickname: 'anonymous',
  picture: 'http://1.gravatar.com/avatar/a9ea323cd7b93a395e27a0af24116252',
  roles: '', // remove after roles beta is done
  features: '', // remove after roles beta is done
  permissions: '',
  permissionsString: '',
  userMeta: { cookieConsent: true },
};

const authService = new AuthenticationService();

const state = {
  auth: null,
  ssoToken: null,
  name: '',
  user: [anonUser],
  requestedPath: null,
  isPendingLogin: false,
  photoVer: 0,
  refreshNav: 0,
};

// Typically, only call mutations from Actions
// ** Mutations are Synchronous **
const mutations = {
  SET_SSO_TOKEN: (state, token) => {
    state.ssoToken = token;
  },
  PENDING_LOGIN: (state, val) => {
    state.isPendingLogin = val;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_PHOTO_VER: (state) => {
    state.photoVer++;
  },
  SET_REFRESH_NAV: (state) => {
    state.refreshNav++;
  },
  SET_USER_AUTH(state, authResult) {
    // console.log('authResult', fromAuth0, JSON.stringify(authResult));
    // Set the time that the access token will expire at, expiresAt:Number = number of seconds until the access token expires
    // authResult.expiresIn = 35; // force to expire in 30 seconds
    // expiresIn: A string containing the expiration time (in seconds) of the accessToken
    authResult.expiresAt = util.expiresDate(authResult.expiresIn || authResult.expires_in).getTime(); // returns number of milliseconds since 1970/01/01: //authResult.expiresIn * 1000 + new Date().getTime(); // convert to

    // Show some timestamps when we expect things to expire...
    // console.log(`Expires In: ${authResult.expiresIn}`);
    // console.log(`Expires At: ${authResult.expiresAt}`);

    // calc expireUTC timestamp
    const target = new Date(authResult.expiresAt);
    // console.log(`Expires UTC: ${target.toUTCString()}`);
    authResult.expiresUTC = target.toUTCString();

    if (authResult?.access_token) {
      authResult.accessToken = authResult.access_token;
    }
    if (authResult?.id_token) {
      authResult.idToken = authResult.id_token;
    }
    if (authResult?.refresh_token) {
      authResult.refreshToken = authResult.refresh_token;
    }
    
    // console.log(JSON.stringify(authResult));

    state.auth = util.pick(authResult, [
      'account',
      'accessToken',
      'idToken',
      'refreshToken',
      'state',
      'expiresIn',
      'tokenType',
      'scope',
      'expiresAt',
      'expiresUTC',
    ]);

    // console.log(JSON.stringify(state.auth));
  },
  UPDATE_USER_PERMISSIONS() {
    location.reload();
  },
  SET_USER(state, user) {
    state.user[0] = user;
    // console.log(`UserAuth set: ${user.email} - Expires ${state.auth.expiresAt}`);
  },
  ADD_USER(state, user) {
    if (state.user.length > 1) {
      state.user[0] = user; // replace the impersonated user with the new user
    } else {
      state.user.unshift(user); // push to the beginning of the user array
    }
  },
  REMOVE_USER(state) {
    state.user.shift(); // remove from the beginning of the user array
    //  Remove LocalStorage on Impersonate
    const toDelete = [];
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).includes('meta-')) {
        toDelete.push(localStorage.key(i));
      }
    }
    for (let i = 0; i < toDelete.length; i++) {
      localStorage.removeItem(toDelete[i]);
    }
    location.reload();
  },
  CLEAR_USER_AUTH(state) {
    // clear the deck - prior presence of user should be purged!
    state.auth = null;
    state.ssoToken = null;
    state.isPendingLogin = false;
    state.user = [anonUser];

    //  Remove LocalStorage on clear user
    const toDelete = [];
    for (let i = 0; i < localStorage.length; i++) {
      if (!localStorage.key(i).includes('meta-') && !localStorage.key(i).includes('via-')) {
        toDelete.push(localStorage.key(i));
      }
    }
    for (let i = 0; i < toDelete.length; i++) {
      localStorage.removeItem(toDelete[i]);
    }
  },
  UPDATE_REQUESTED_PATH(state, value) {
    state.requestedPath = value;
  },
  /**
   * For Debug use only
   */
  EXPIRE_AUTH(state) {
    const auth = state.auth;
    auth.expiresAt = new Date().getTime() - 5000; // set auth expired to 5 seconds ago
    auth.expiresIn = 5;
    state.auth = auth;
  },
};

// Actions: arbitrary asynchronous operations.
// typically called from dispatch e.g. this.$store.dispatch("account/setImpersonation", usr);
const actions = {
  syncUserPermissions({ commit, getters }) {
    const isAuthUser = getters.isAuthenticated();
    if (isAuthUser) {
      const usr = getters.user;
      const isImpersonated = usr.auth0Id?.startsWith('imp|');
      if (!isImpersonated) {

        // Get the new permissions 
        ApiService.apiCore.get(`/core/permission/user/${usr.id}?$expand=[userRoles,permissions]`).then((resp) => {
          if (resp && resp.status === 200) {
            if (resp.data) {
              const { permissions, userRoles } = resp.data.Results;
              usr.permissionsString =  permissions?.map((p) => p.permissionCode).toString();
              if (usr.permissions != permissions) {
                usr.permissions = permissions;
              }
              if (usr.userRoles != userRoles) {
                usr.userRoles = userRoles;
              }
            }
          }
        });

        ApiService.apiCore.get(`/core/users/authid/${usr.auth0Id}`).then((resp) => {
          if (resp && resp.status === 200) {
            if (resp.data) {
              const { roles, features } = resp.data;
              if (usr.roles != roles || usr.features != features) {
                usr.roles = roles;
                usr.features = features;
              }
            }
          }
        });
      }
    }
  },
  // TODO: Do we need this- search for store.dispatch("account/validateLogin") or some variation
  // generic function - will try to determine if user authenticates through Azure AD SSO or Auth0 Identity Provider
  loginUser({ getters, dispatch }, redirectName) {
    const idpUser = getters.ssoToken;
    if (idpUser && idpUser.account) {
      return dispatch('loginSSOUser2', redirectName || 'people/eix');
    } else {
      return dispatch('loginAuth0User', redirectName);
    }
  },
  resetRequestedPath({ commit }) {
    commit('UPDATE_REQUESTED_PATH', null);
  },
  saveRedirectPath({ commit }, path) {
    if (path !== '/[object%20Object]') {
      commit('UPDATE_REQUESTED_PATH', path);
    }
  },

  // TODO: Do we need this- search for store.dispatch("account/validateLogin") or some variation
  async validateLogin() {
    return true;
  },

  // path should be of relative url path e.g. '/' or perhaps '/dashboard/main'
  redirectToPath(path) {
    location.pathname = path;
  },

  ping(context, value) {
    // console.log('test', value);
    //window.console.log('log from function outside component.')
  },

  // called from autho365 refreshToken exception - something bad must have happened
  clearUser({ commit }) {
    commit('CLEAR_USER_AUTH');
  },
  async logoutUser({ commit }) {
    // console.log('Log out via Account.module.js');
    commit('CLEAR_USER_AUTH');
    await authService.logoutUser({
      logoutParams: {
        returnTo: `${window.location.origin}`,
      },
    });
  },

  pendingLogin({ commit }, state) {
    commit('PENDING_LOGIN', state);
  },

  setPhotoVer({ commit }, state) {
    commit('SET_PHOTO_VER', state);
  },

  setRefreshNav({ commit }, state) {
    commit('SET_REFRESH_NAV', state);
  },

  setUser({ commit }, user) {
    commit('SET_USER', user);
  },

  setImpersonation({ commit, getters }, user) {
    commit('ADD_USER', user);
  },

  clearImpersonation({ commit }) {
    commit('REMOVE_USER');
  },

  expireAuth({ commit }) {
    commit('EXPIRE_AUTH');
  },

  refreshToken() {
    return authService.refreshToken();
  },
};

const getters = {
  isImpersonated: (state) => {
    return state.user.length > 1;
  },
  ssoToken: (state) => {
    return state.ssoToken;
  }, // SSO Auth Token
  /******
   * Check whether the current token has expired
   * Getter is defined as a function to avoid cached/dirty evaluation,
   * a function will be eval'd for every call
   */
  isAuthenticated: (state) => () => {
    let validAuthToken = false;
    let usr = {};
    if (!Array.isArray(state.user)) {
      usr = [state.user];
    } else {
      usr = state.user[state.user.length - 1];
    }
    const isNamedUser = usr && usr.name != 'Unknown';

    if (state.auth && state.auth?.expiresAt && isNamedUser) {
      const locTime = new Date().getTime(); // number of milliseconds since 1 January 1970 00:00:00 UTC.
      validAuthToken = locTime < state.auth.expiresAt;
    }
    // console.log(state, validAuthToken)
    return validAuthToken;
  },
  /************
   * Return the actual logged in User, regardless of Impersonation
   */
  loggedInUser: (state) => {
    if (!Array.isArray(state.user)) {
      state.user = [state.user];
    }
    return state.user[state.user.length - 1]; // fastest, though maybe not as readable as state.user.slice(-1)[0]
  },
  user: (state) => {
    if (!Array.isArray(state.user)) {
      state.user = [state.user];
    }
    return state.user[0];
  },
  defaultHref: (state) => {
    // this is the default page to redirect to after login, we should revisit this
    // TODO: redo based on new roles and permissions

    const userHasPeople = hasPermission('view_people') || hasPermission('full_workforce');
    const userCanSeeHomepage =
      // can see tickets
      hasPermission('view_ticket') || hasPermission('full_service_desk') ||
      // can see logistics
      hasPermission('view_asset') ||hasPermission('full_logistics');
    if (userCanSeeHomepage) {
      return '/dashboard/main';
    } else if (userHasPeople) {
      return '/people/ex/search';
    } else {
      return '/';
    }
  },
  defaultNamedRoute: () => {
    return 'Home';
  },
  alerts: (state) => {
    return state.alerts;
  },
  hasMultipleCompanies: (state) => {
    if (state.user[0]) {
      if (hasPermission() || hasPermission('show_all_companies')) {
        return true;
      }
      if (state.user[0].tenant.companies.length > 1) return true;
    }
    return false;
  },
  activeToken: (state) => {
    return state.ssoToken;
  },
  requestedPath: (state) => {
    if (state.requestedPath && Array.isArray(state.requestedPath)) {
      state.requestedPath = null;
    }
    return state.requestedPath;
  },
  pendingLogin: (state) => {
    return state.isPendingLogin;
  },
  authService: () => {
    return authService;
  },
  photoVer: (state) => {
    return state.photoVer;
  },
  refreshNav: (state) => {
    return state.refreshNav;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
