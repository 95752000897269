import apiCore from '@/api/api.common';
// import { Cache } from 'axios-extensions';
// import util from "@/util";
import store from '@/store/state';
import issueTypes from '@/api/core/lookups/issueTypes';
import priorityTypes from '@/api/core/lookups/prioritiesTypes';
import statusTypes from '@/api/core/lookups/statusTypes';
import subIssueTypes from '@/api/core/lookups/subissueTypes';
import sources from '@/api/core/lookups/sources';
import types from '@/api/core/lookups/types';
import rmaInitialDiagnosis from '@/api/core/lookups/rmaInitialDiagnosis';
// import { Ticket, RMA } from '@/model/index';
import rmaFinalDiagnosis from '@/api/core/lookups/rmaFinalDiagnosis';
import hardwareStateTypes from '@/api/core/lookups/hardwareStateTypes';
import hardwareBrandTypes from '@/api/core/lookups/hardwareBrandTypes';
import hardwareStatusReasons from '@/api/core/lookups/rmaHardwareStatusReasons';
import shippingOptions from '@/api/core/lookups/shippingOptions';

import { format } from 'date-fns';

// const FIVE_MINUTES = 1000 * 60 * 5;
// const CAPACITY = 100;
// const localCache = new Cache({ ttl: FIVE_MINUTES, max: CAPACITY });
const dftOptions = { useCache: true };

const repo = {
  apiCore: apiCore,
  getRMAs: getRMAs,
  getTicketRMAs: getTicketRMAs, // his returns full ticket.rma data such for where entire where clause use
  getTicketRMAsV2: getTicketRMAsV2,
  getTicketRMAsV3: getTicketRMAsV3,
  getRMAById: getRMAById,
  getReturnToStockById: getReturnToStockById,
  updateRMAAdvanced: updateRMAAdvanced,
  updateAdvancedShipLabels: updateAdvancedShipLabels, // new advanced endpoint to create ship labels
  updateRMARegular: updateRMARegular,
  stageReturnToStock: stageReturnToStock,
  getEmployees: getEmployees,
  getStock: getStock,
  getRMAShipped: getRMAShipped,
  getAvgSla1: getAvgSla1,
  RMACountsByDate: RMACountsByDate,
  RMAStatusCountsByDate: RMAStatusCountsByDate,
  RMASInitialDiagnosisCounts: RMASInitialDiagnosisCounts,
  addTicketNote: addTicketNote,
  // Hardware Devices (RMA)
  getAssets: getAssets,
  getAssetStateCount: getAssetStateCount,
  getAssetStateCountByCategory: getAssetStateCountByCategory,
  getAssetStateCountByProductName: getAssetStateCountByProductName,
  getAssetWarrantyExpCounts: getAssetWarrantyExpCounts,
  postAsset: postAsset,
  getWarrantyExpires: getWarrantyExpires,

  lookups: {
    types: types,
    sources: sources,
    statusTypes: statusTypes,
    priorityTypes: priorityTypes,
    issueTypes: issueTypes,
    subIssueTypes: subIssueTypes,
    rmaInitialDiagnosis: rmaInitialDiagnosis,
    rmaFinalDiagnosis: rmaFinalDiagnosis,
    shippingOptions: shippingOptions,
    hardwareStatusReasons: hardwareStatusReasons.filter((s) => s.isActive),
    hardwareStateTypes: hardwareStateTypes.filter((s) => s.isActive),
    hardwareBrandTypes: hardwareBrandTypes.filter((s) => s.isActive),
  },
  graphs: {
    RMACountsByDateChart: RMACountsByDateChart,
    RMAStatusCountsByDateChart: RMAStatusCountsByDateChart,

    RMASLA1ByDateCounts: RMASLA1ByDateCounts,
    RMAPriorityByIssueTypeCounts: RMAPriorityByIssueTypeCounts,
    // Hardware Devices
    RMAInventoryCountByProductChart: rmaInventoryCountByProductChart,
    RMAAssetStateByCategoryColumnChart: RMAAssetStateByCategoryColumnChart,
    RMAAssetWarrantyExpDateColumnChart: RMAAssetWarrantyExpDateColumnChart,
    RMAAssetStateByProductNameColumnChart: RMAAssetStateByProductNameColumnChart,
  },
};

function applyOpts(options) {
  return Object.assign(options || dftOptions);
}

/*
RMA API functions
*/
// This is unique to rma's, in that it will update BMS,VIA, and FreshService if ticket is sync'd
function addTicketNote(id, noteSummary, rma) {
  if (noteSummary && noteSummary.length > 1) {
    const user = store.getters['account/user']; //store.state.account.user;
    const payload = {
      rma: rma || {},
      noteType: 'General Notes',
      noteSummary: noteSummary,
      isInternal: false,
      noteDate: new Date(Date.now()).toISOString(),
      senderEmail: user.email,
    };
    return apiCore.post(`/core/bms/rma/tickets/rma/${id}/note`, payload);
  } else return null;
}

function getRMAs(options) {
  return apiCore.get('/core/bms/rma/tickets', applyOpts(options));
}

/**
 * This returns full ticket.rma data such for where entire where clause use
 * @param {*} options
 */
function getTicketRMAs(options) {
  return apiCore.get('/core/bms/rma/tickets/rmas', applyOpts(options));
}

function getTicketRMAsV2(options) {
  return apiCore.get('/core/bms/rma/tickets/rmas/V2', applyOpts(options));
}

function getTicketRMAsV3(options) {
  return apiCore.get('/core/bms/rma/tickets/rmas/V3', applyOpts(options));
}

function getRMAById(id, options) {
  const opt = Object.assign({ useCache: false }, options || {});

  return apiCore.get(`/core/bms/rma/tickets/${id}`, opt).then((resp) => {
    if (resp && resp.status === 200) {
      const orig = resp.data.Results;
      if (orig && orig.notes) {
        orig.notes.forEach(remapTicketRefs);
      }

      //resp.data.Results = Ticket.FromAPI(orig);
      /* orig.statusChanges = orig.statusChanges || [];
                orig.priorityChanges = orig.priorityChanges || [];
                orig.notes = orig.notes || [];
                orig.attachments = orig.attachments || [];
                orig.timeLogs = orig.timeLogs || [];
                orig.rma = orig.rma || {}; */
    }
    return resp;
  });
}

function getReturnToStockById(id, options) {
  const opt = Object.assign({ useCache: false }, options || {});

  return apiCore.get(`/core/bms/rma/tickets/rmas/V3/${id}`, opt).then((resp) => {
    if (resp && resp.status === 200) {
      const orig = resp.data.Results;
      if (orig && orig.notes) {
        orig.notes.forEach(remapTicketRefs);
      }
    }
    return resp;
  });
}

function stageReturnToStock(payload) {
  return apiCore.patch(`/core/bms/rma/stageReturnToStock`, payload).then((resp) => {
    return resp;
  });
}

// Ticket Notes can include <a> links ref other bms.kaseya.com tickets, update those links for Montra portal display
function remapTicketRefs(note) {
  if (note.noteSummary) {
    note.noteSummary = note.noteSummary.replaceAll(
      'https://bms.kaseya.com/MSP/TicketEdit.aspx?ID=',
      '/servicedesk/admin/ticketDetail?id=',
      true,
    );
  }
}
function updateAdvancedShipLabels(payload) {
  return apiCore.post(`/core/bms/rma/stageAdvancedShipLabels`, payload).then((resp) => {
    if (resp && resp.statusText === 'Created') {
      const orig = resp.data.Results;
      if (orig && orig.notes) {
        orig.notes.forEach(remapTicketRefs);
      }
    }
    return resp;
  });
}
function updateRMAAdvanced(payload) {
  return apiCore.post(`/core/bms/rma/stageAdvanced`, payload).then((resp) => {
    if (resp && resp.statusText === 'Created') {
      const orig = resp.data.Results;
      if (orig && orig.notes) {
        orig.notes.forEach(remapTicketRefs);
      }
    }
    return resp;
  });
}
function updateRMARegular(payload) {
  return apiCore.post(`/core/bms/rma/stageRegular`, payload).then((resp) => {
    if (resp && resp.statusText === 'Created') {
      const orig = resp.data.Results;
      if (orig && orig.notes) {
        orig.notes.forEach(remapTicketRefs);
      }
    }
    return resp;
  });
}

function getEmployees(options) {
  return apiCore.get('/core/users/employees', applyOpts(options));
}

function getStock(options) {
  return apiCore.get('/core/bms/rma/assets', applyOpts(options));
}

/***********************
 * Hardware Devices
 */
function getAssets(options) {
  return apiCore.get('/core/bms/rma/assets/', applyOpts(options));
}
function getAssetStateCount(options) {
  return apiCore.get('/core/bms/rma/assets/stateCounts', applyOpts(options));
}
function getAssetStateCountByCategory(options) {
  return apiCore.get('/core/bms/rma/assets/stateCountsByCategory', applyOpts(options));
}
function getAssetStateCountByProductName(options) {
  return apiCore.get('/core/bms/rma/assets/stateCountsByProductName', applyOpts(options));
}
function getAssetWarrantyExpCounts(options) {
  return apiCore.get('/core/bms/rma/assets/warrantyExpCounts', applyOpts(options));
}

async function postAsset(payload) {
  return apiCore.post('/core/bms/rma/assets', payload);
  //return new Promise(resolve => { setTimeout(() => resolve({TotalRecords:1,Results:{},Status:"Ok",StatusCode:200}), 3000); });
}
/************************
 * Graph/Chart helpers
 *************************/

async function RMAAssetStateByProductNameColumnChart(options) {
  const chartData = {
    series: [],
    categories: [],
  };
  const resp = await getAssetStateCountByProductName(options);
  if (resp && resp.status === 200) {
    const coll = resp.data.Results;
    chartData.categories = coll.map((i) => {
      return i.productName;
    });
    chartData.series = [
      {
        name: 'Deployed',
        data: coll.map((i) => {
          return i['assigned'];
        }),
      },
      {
        name: 'In Stock',
        data: coll.map((i) => {
          return i['provisioned'];
        }),
      },
      {
        name: 'In Repair',
        data: coll.map((i) => {
          return i['inRepair'];
        }),
      },
      {
        name: 'Retired',
        data: coll.map((i) => {
          return i['expired'];
        }),
      },
      {
        name: 'Pending Vendor',
        data: coll.map((i) => {
          return i['pendingVendor'];
        }),
      },
      {
        name: 'Unable to Repair',
        data: coll.map((i) => {
          return i['unableToRepair'];
        }),
      },
    ];
  }
  //console.log(JSON.stringify(chartData,null,2));
  return chartData;
}

async function RMAAssetWarrantyExpDateColumnChart(options) {
  const chartData = {
    series: [],
    categories: [],
  };
  const resp = await getAssetWarrantyExpCounts(options);
  if (resp && resp.status === 200) {
    const coll = resp.data.Results;
    chartData.categories = [];
    chartData.series = [
      {
        name: 'Deployed',
        data: [],
      },
      {
        name: 'In Repair',
        data: [],
      },
      {
        name: 'In Stock',
        data: [],
      },
      {
        name: 'Pending Vendor',
        data: [],
      },
    ];
    coll.forEach((i) => {
      if (i['expireDate'] !== null) {
        chartData.categories.push(format(new Date(i['expireDate']), 'MM/yy'));
      } else {
        chartData.categories.push('Unknown');
      }
      chartData.series[0].data.push(i['assigned'] ? i['assigned'] : 0);
      chartData.series[1].data.push(i['inRepair'] ? i['inRepair'] : 0);
      chartData.series[2].data.push(i['provisioned'] ? i['provisioned'] : 0);
      chartData.series[3].data.push(i['pendingVendor'] ? i['pendingVendor'] : 0);
    });
  }
  return chartData;
}
/** RMA Inventory Counts By Product for Half-Circle donut charts */
async function rmaInventoryCountByProductChart(options) {
  const opt = Object.assign({ $filter: "state eq 'Provisioned'" }, options || {});
  // data shape the chart is expecting
  const data = {
    eliteDesk: { minStock: 10, targetStock: 30, inStock: 0 },
    z1: { minStock: 10, targetStock: 30, inStock: 0 },
    z2: { minStock: 10, targetStock: 30, inStock: 0 },
    z240: { minStock: 10, targetStock: 30, inStock: 0 },
    z440: { minStock: 10, targetStock: 30, inStock: 0 },
    s1: { minStock: 10, targetStock: 20, inStock: 0 },
    s2V1: { minStock: 10, targetStock: 20, inStock: 0 },
    s2V2: { minStock: 10, targetStock: 20, inStock: 0 },
    s2V3: { minStock: 10, targetStock: 20, inStock: 0 },
    nic: { minStock: 10, targetStock: 20, inStock: 0 },
    graphics: { minStock: 10, targetStock: 20, inStock: 0 },
  };

  const resp = await apiCore.get('/core/bms/rma/assets/stateCounts', applyOpts(opt));
  if (resp && resp.status == 200) {
    const results = resp.data.Results;
    results.forEach((i) => {
      switch (i.category) {
        case 'Windows':
          switch (i.productName) {
            case 'Z1':
            case 'Z1 G5':
            case 'Z1 G6':
              data['z1'].inStock += i['provisioned'];
              break;
            case 'Z2':
            case 'Z2 G9':
            case 'HP Z2 G9':
              data['z2'].inStock = i['provisioned'];
              break;
            case 'EliteDesk 800 G4':
            case 'Elitedesk 800 G4':
            case 'HP 800 G4':
              data['eliteDesk'].inStock += i['provisioned'];
              break;
            case 'Z240':
              data['z240'].inStock = i['provisioned'];
              break;
            case 'Z440':
              data['z440'].inStock = i['provisioned'];
              break;
          }
          break;
        case 'Graphics Card':
          data['graphics'].inStock += i['provisioned'];
          break;
        case 'NIC':
          data['nic'].inStock += i['provisioned'];
          break;
        case 'Camera':
          switch (i.productName) {
            case 'S1':
              data['s1'].inStock = i['provisioned'];
              break;
            case 'S2V1':
              data['s2V1'].inStock = i['provisioned'];
              break;
            case 'S2V2':
              data['s2V2'].inStock = i['provisioned'];
              break;
            case 'S2V3':
              data['s2V3'].inStock = i['provisioned'];
              break;
          }
          break;
      }
    });
  }

  return data;
}
async function RMAAssetStateByCategoryColumnChart(options) {
  const chartData = {
    series: [],
    categories: [],
  };
  const resp = await getAssetStateCountByCategory(options);
  if (resp && resp.status === 200) {
    const coll = resp.data.Results;
    chartData.categories = coll.map((i) => {
      return i.category.replace(/Windows/g, 'VPU');
    }); // Camera, Graphics Card, NIC, VPU (Windows)
    chartData.series = [
      {
        name: 'Retired',
        data: coll.map((i) => {
          return i['expired'];
        }),
      },
      {
        name: 'Deployed',
        data: coll.map((i) => {
          return i['assigned'];
        }),
      },
      {
        name: 'In Repair',
        data: coll.map((i) => {
          return i['inRepair'];
        }),
      },
      {
        name: 'In Stock',
        data: coll.map((i) => {
          return i['provisioned'];
        }),
      },
      {
        name: 'Pending Vendor',
        data: coll.map((i) => {
          return i['pendingVendor'];
        }),
      },
      {
        name: 'Unable to Repair',
        data: coll.map((i) => {
          return i['unableToRepair'];
        }),
      },
    ];
  }
  return chartData;
}
function getRMAShipped(options) {
  return apiCore.get('/core/bms/rma/assets/shippedCounts', applyOpts(options));
}

function getAvgSla1(options) {
  return apiCore.get('/core/bms/rma/tickets/avgSla1', applyOpts(options));
}
function RMACountsByDate(options) {
  return apiCore.get('/core/bms/rma/tickets/rma/countsByDate', applyOpts(options));
}

async function RMACountsByDateChart(options) {
  const chartData = {
    series: [],
    categories: [],
  };
  const resp = await RMACountsByDate(options);
  if (resp && resp.status === 200) {
    const coll = resp.data.Results.series;
    chartData.categories = coll.map((i) => {
      return i.openDate;
    });
    // const totals = coll.map(i => { return i.total; }); // convert to string
    const vpu = coll.map((i) => {
      return i.vpu;
    }); // convert to string
    const cameras = coll.map((i) => {
      return i.camera;
    }); // convert to string
    //chartData.series = [{ name: 'total', data: totals }]; // if we just wanted totals
    chartData.series = [
      { name: 'VPU', data: vpu },
      { name: 'Camera', data: cameras },
    ];
  }
  return chartData;
}

function RMAStatusCountsByDate(options) {
  return apiCore.get('/core/bms/rma/tickets/rma/statusCountsByDate', applyOpts(options));
}

async function RMAStatusCountsByDateChart(options) {
  const chartData = {
    series: [],
    categories: [],
  };
  const resp = await RMAStatusCountsByDate(options);
  if (resp && resp.status === 200) {
    const coll = resp.data.Results;
    chartData.categories = coll.map((i) => {
      return new Date(i.openDate).toISOString().split('T')[0];
    });
    //chartData.series = [{ name: 'total', data: totals }]; // if we just wanted totals
    chartData.series = [
      {
        name: 'Assigned',
        data: coll.map((i) => {
          return i['Assigned'];
        }),
      },
      {
        name: 'Completed',
        data: coll.map((i) => {
          return i['Completed'];
        }),
      },
      {
        name: 'Customer Responded',
        data: coll.map((i) => {
          return i['Customer Responded'];
        }),
      },
      {
        name: 'In Progress',
        data: coll.map((i) => {
          return i['In Progress'];
        }),
      },
      {
        name: 'Pending Customer',
        data: coll.map((i) => {
          return i['Pending Customer'];
        }),
      },
      {
        name: 'Pending Vendor',
        data: coll.map((i) => {
          return i['Pending Vendor'];
        }),
      },
      {
        name: 'Scheduled',
        data: coll.map((i) => {
          return i['Scheduled'];
        }),
      },
    ];
  }
  return chartData;
}

function RMASInitialDiagnosisCounts(options) {
  return apiCore.get('/core/bms/rma/tickets/rma/initialDiagnosisCounts', applyOpts(options));
}

async function RMASLA1ByDateCounts(options) {
  const chartData = {
    series: [],
    categories: [],
  };
  const resp = await apiCore.get('/core/bms/rma/tickets/avgSla1Months', applyOpts(options));
  if (resp && resp.status === 200) {
    const coll = resp.data.Results;
    chartData.categories = coll.map((i) => {
      return new Date(i.outShipDate).toISOString().split('T')[0];
    });
    //chartData.series = [{ name: 'total', data: totals }]; // if we just wanted totals
    chartData.series = [
      {
        name: 'SLA',
        data: coll.map((i) => {
          return Number.parseFloat(i.avgSla1).toFixed(0);
        }),
      },
    ];
  }
  return chartData;
}

async function RMAPriorityByIssueTypeCounts(options) {
  const chartData = {
    series: [],
    categories: [],
  };
  const resp = await apiCore.get('/core/bms/rma/tickets/priorityCountsByIssueType', applyOpts(options));
  if (resp && resp.status === 200) {
    const coll = resp.data.Results;
    chartData.categories = coll.map((i) => {
      return i.priority;
    });
    chartData.series = [
      {
        name: 'VPU',
        data: coll.map((i) => {
          return i['User: Workstation'];
        }),
      },
      {
        name: 'Camera',
        data: coll.map((i) => {
          return i['Camera'];
        }),
      },
    ];
  }

  return chartData;
}

function getWarrantyExpires(id, options) {
  return apiCore.get(`/core/bms/rma/maint/hardwareAsset/warrantyExpires/${id}`, applyOpts(options));
}

export default repo;
