import apiCore from '@/api/api.common';
// import { Cache } from 'axios-extensions';
// import util from "@/util";

// const SIXTY_MINUTES = 1000 * 60 * 60;
// const CAPACITY = 100;
// const azureCache = new Cache({ ttl: SIXTY_MINUTES, max: CAPACITY });
const dftOptions = { useCache: true };

const repo = {
  apiCore: apiCore,
  getLangs: getLangs,
  getLogs: getLogs,
  updatePhoto: updatePhoto,
  getTransactionLogs: getTransactionLogs,
  config: {
    options: {
      get: getConfigOptions,
      post: postConfigOptions,
      patch: patchConfigOptions,
      delete: deleteConfigOptions,
    },
    mappings: {
      get: getITSMMappings,
      post: postITSMMappings,
      put: putITSMMappings,
      delete: deleteITSMMappings,
    },
    itsm: {
      post: syncITSM,
      delete: resetITSMMappings,
      resetITSMMappings: resetITSMMappings,
    },
    servicenow: {
      get: getServiceNowProperties,
    },
    serviceNowDefaults: {
      get: getServiceNowDefaults,
      patch: patchServiceNowDefaults,
    },
    targets: {
      get: getFieldTargets,
    },
    alerts: {
      get: getAlertForStaleTicketsOption,
      patch: patchAlertForStaleTicketsOption,
    },
    assets: {
      get: getAssets,
      getManaged: getAssetsManaged,
      canDelete: canDeleteAsset,
      getById: getAsset,
      getByDeviceName: getAssetByName,
      post: postAssets,
      patch: patchAssets,
      delete: deleteAssets,
    },
    assetAssignment: {
      get: getAssetAssignment,
      post: postAssetAssignment,
      patch: patchAssetAssignment,
      delete: deleteAssetAssignment,
    },
    assetAssignmentLicenses: {
      get: getAssetAssignmentLicenses,
    },
    assignAsset: {
      post: assignAsset,
    },
    unassignAsset: {
      post: unassignAsset,
    },
    assetLogs: {
      get: getAssetLogs,
      post: postAssetLogs,
      patch: patchAssetLogs,
      delete: deleteAssetLogs,
    },
    assignmentTypes: {
      get: getAssignmentTypes,
      post: postAssignmentTypes,
      patch: patchAssignmentTypes,
      delete: deleteAssignmentTypes,
    },
    assetConditions: {
      get: getAssetConditions,
      post: postAssetConditions,
      patch: patchAssetConditions,
      delete: deleteAssetConditions,
    },
    assetTypes: {
      get: getAssetTypes,
      post: postAssetTypes,
      patch: patchAssetTypes,
      delete: deleteAssetTypes,
      template: getAssetTypeTemplate,
      import: importAssetTypeTemplateAssets,
      readImport: readImport,
      importAsset: importAsset,
    },
    categories: {
      get: getCategories,
      post: postCategories,
      patch: patchCategories,
      delete: deleteCategories,
    },
    changeTypes: {
      get: getChangeTypes,
      post: postChangeTypes,
      patch: patchChangeTypes,
      delete: deleteChangeTypes,
    },
    assetStates: {
      get: getAssetStates,
      post: postAssetStates,
      patch: patchAssetStates,
      delete: deleteAssetStates,
    },
    licenseProviderTemplates: {
      get: getLicenseProviderTemplates,
      post: postLicenseProviderTemplates,
      patch: patchLicenseProviderTemplates,
      delete: deleteLicenseProviderTemplates,
    },
    licenseProviders: {
      get: getLicenseProvider,
      post: postLicenseProvider,
      patch: patchLicenseProvider,
      delete: deleteLicenseProvider,
      getUsers: getUsersByLicenseProvider,
    },
    licenses: {
      assign: assignLicense,
      unassignLicense: unassignLicense,
    },
    assetLocationHistory: {
      get: getAssetLocationHistory,
    },
    shipping: {
      validateShippingCreds: validateShippingCreds,
      uploadShippingCreds: uploadShippingCreds,
    },
    reasonTypes: {
      get: getReasonTypes,
      post: postReasonTypes,
      patch: patchReasonTypes,
      delete: deleteReasonTypes,
    },
  },
};

function applyOpts(options) {
  return Object.assign(options || dftOptions);
}

function getLangs(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/tenants/personalized/keys', applyOpts(optionsObj));
}

/* Config Options */
function getConfigOptions(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/dx/config/options', applyOpts(optionsObj));
}
function postConfigOptions(payload) {
  return apiCore.post('/core/dx/config/options', payload);
}
function patchConfigOptions(id, payload) {
  return apiCore.patch(`/core/dx/config/options/${id}`, payload);
}
function deleteConfigOptions(id) {
  return apiCore.delete(`/core/dx/config/options/${id}`);
}

function getAlertForStaleTicketsOption() {
  return apiCore.get(`/core/alerts/alertForStaleTicketsOption/`);
}

function patchAlertForStaleTicketsOption(payload) {
  return apiCore.patch(`/core/alerts/alertForStaleTicketsOption/`, payload);
}

/* Config ITSM Mappings */
function getITSMMappings(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/dx/config/tickets/fieldMap', applyOpts(optionsObj));
}
function postITSMMappings(payload) {
  return apiCore.post('/core/dx/config/tickets/fieldMap', payload);
}
function putITSMMappings(id, payload) {
  return apiCore.put(`/core/dx/config/tickets/fieldMap/${id}`, payload);
}
function deleteITSMMappings(id) {
  return apiCore.delete(`/core/dx/config/tickets/fieldMap/${id}`);
}

function getLogs(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/dx/logs', applyOpts(optionsObj));
}

/* Config ITSM Actions */
function syncITSM(id, payload) {
  return apiCore.post(`/core/dx/config/tickets/syncData/${id}`, payload);
}

function resetITSMMappings(id, payload) {
  return apiCore.post(`/core/dx/config/tickets/resetTicketMappings/${id}`, payload);
}

/* Config Montra Props */
function getServiceNowProperties(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get(`/servicenow/ticket/properties`, applyOpts(optionsObj));
}

function getServiceNowDefaults(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get(`/servicenow/defaultProperties`, applyOpts(optionsObj));
}

function patchServiceNowDefaults(payload) {
  return apiCore.patch(`/servicenow/defaultProperties`, payload);
}

function getTransactionLogs(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/dx/logs/transaction/latest', applyOpts(optionsObj));
}

/* Config HRIS Field Targets */
function getFieldTargets() {
  return apiCore.get('/core/dx/config/itsm/fieldTargets');
}

/* Asset with Integration */
function getAsset(id, payload) {
  return apiCore.get(`/core/dx/assets/${id}`, payload);
}

function getAssetByName(name, payload) {
  return apiCore.get(`/core/dx/assets/name/${name}`, payload);
}

function getAssets(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/dx/assets', applyOpts(optionsObj));
}
function getAssetsManaged(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/dx/assets/managed', applyOpts(optionsObj));
}
function canDeleteAsset(id, options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get(`/core/dx/assets/canDelete/${id}`, applyOpts(optionsObj));
}
function postAssets(payload) {
  return apiCore.post('/core/dx/assets', payload);
}
function patchAssets(id, payload) {
  return apiCore.patch(`/core/dx/assets/${id}`, payload);
}
function deleteAssets(id) {
  return apiCore.delete(`/core/dx/assets/${id}`);
}

/* Asset with Integration */
function getAssetAssignment(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/dx/assetAssignment', applyOpts(optionsObj));
}
function postAssetAssignment(payload) {
  return apiCore.post('/core/dx/assetAssignment', payload);
}
function patchAssetAssignment(id, payload) {
  return apiCore.patch(`/core/dx/assetAssignment/${id}`, payload);
}
function deleteAssetAssignment(id) {
  return apiCore.delete(`/core/dx/assetAssignment/${id}`);
}

/* Licenses By User */
function getAssetAssignmentLicenses(id, payload) {
  return apiCore.get(`/core/dx/licenses/user/${id}`, payload);
}

/* Assign Asset */
function assignAsset(id, payload) {
  return apiCore.post(`/core/dx/assets/${id}/assignUser`, payload);
}
function unassignAsset(id, payload) {
  return apiCore.post(`/core/dx/assets/${id}/unassignUser`, payload);
}

/* Asset Location History */
function getAssetLocationHistory(id, options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get(`/core/dx/assets/${id}`, applyOpts(optionsObj));
}

/* Asset with Integration */
function getAssetLogs(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/dx/assetLog', applyOpts(optionsObj));
}
function postAssetLogs(payload) {
  return apiCore.post('/core/dx/assetLog', payload);
}
function patchAssetLogs(id, payload) {
  return apiCore.patch(`/core/dx/assetLog/${id}`, payload);
}
function deleteAssetLogs(id) {
  return apiCore.delete(`/core/dx/assetLog/${id}`);
}

/* AssignmentTypes */
function getAssignmentTypes(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/dx/config/assignmentType', applyOpts(optionsObj));
}
function postAssignmentTypes(payload) {
  return apiCore.post('/core/dx/config/assignmentType', payload);
}
function patchAssignmentTypes(id, payload) {
  return apiCore.patch(`/core/dx/config/assignmentType/${id}`, payload);
}
function deleteAssignmentTypes(id) {
  return apiCore.delete(`/core/dx/config/assignmentType/${id}`);
}

/* AssetConditions */
function getAssetConditions(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/dx/config/assetCondition', applyOpts(optionsObj));
}
function postAssetConditions(payload) {
  return apiCore.post('/core/dx/config/assetCondition', payload);
}
function patchAssetConditions(id, payload) {
  return apiCore.patch(`/core/dx/config/assetCondition/${id}`, payload);
}
function deleteAssetConditions(id) {
  return apiCore.delete(`/core/dx/config/assetCondition/${id}`);
}

/* assetTypes */
function getAssetTypes(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/dx/config/assetType', applyOpts(optionsObj));
}
function postAssetTypes(payload) {
  return apiCore.post('/core/dx/config/assetType', payload);
}
function patchAssetTypes(id, payload) {
  return apiCore.patch(`/core/dx/config/assetType/${id}`, payload);
}
function deleteAssetTypes(id) {
  return apiCore.delete(`/core/dx/config/assetType/${id}`);
}

/* Categories */
function getCategories(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/dx/config/assetCategory', applyOpts(optionsObj));
}
function postCategories(payload) {
  return apiCore.post('/core/dx/config/assetCategory', payload);
}
function patchCategories(id, payload) {
  return apiCore.patch(`/core/dx/config/assetCategory/${id}`, payload);
}
function deleteCategories(id) {
  return apiCore.delete(`/core/dx/config/assetCategory/${id}`);
}

/* Change Types */
function getChangeTypes(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/dx/config/changeType', applyOpts(optionsObj));
}
function postChangeTypes(payload) {
  return apiCore.post('/core/dx/config/changeType', payload);
}
function patchChangeTypes(id, payload) {
  return apiCore.patch(`/core/dx/config/changeType/${id}`, payload);
}
function deleteChangeTypes(id) {
  return apiCore.delete(`/core/dx/config/changeType/${id}`);
}

/* Reason Types */
function getReasonTypes(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/dx/config/reasonType', applyOpts(optionsObj));
}
function postReasonTypes(payload) {
  return apiCore.post('/core/dx/config/reasonType', payload);
}
function patchReasonTypes(id, payload) {
  return apiCore.patch(`/core/dx/config/reasonType/${id}`, payload);
}
function deleteReasonTypes(id) {
  return apiCore.delete(`/core/dx/config/reasonType/${id}`);
}

/* Asset States */
function getAssetStates(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/dx/config/assetState', applyOpts(optionsObj));
}
function postAssetStates(payload) {
  return apiCore.post('/core/dx/config/assetState', payload);
}
function patchAssetStates(id, payload) {
  return apiCore.patch(`/core/dx/config/assetState/${id}`, payload);
}
function deleteAssetStates(id) {
  return apiCore.delete(`/core/dx/config/assetState/${id}`);
}

/* License Templates */
function getLicenseProviderTemplates(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/dx/licensetemplates', applyOpts(optionsObj));
}
function postLicenseProviderTemplates(payload) {
  return apiCore.post('/core/dx/licensetemplates', payload);
}
function patchLicenseProviderTemplates(id, payload) {
  return apiCore.patch(`/core/dx/licensetemplates/${id}`, payload);
}
function deleteLicenseProviderTemplates(id) {
  return apiCore.delete(`/core/dx/licensetemplates/${id}`);
}

/* License Provider */
function getLicenseProvider(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/dx/licenses', applyOpts(optionsObj));
}
function postLicenseProvider(payload) {
  return apiCore.post('/core/dx/licenses', payload);
}
function patchLicenseProvider(id, payload) {
  return apiCore.patch(`/core/dx/licenses/${id}`, payload);
}
function getUsersByLicenseProvider(id, payload) {
  return apiCore.get(`/core/dx/licenses/${id}/users`, payload);
}
function deleteLicenseProvider(id) {
  return apiCore.delete(`/core/dx/licenses/${id}`);
}

/* Licenses and other APIS */
function assignLicense(payload) {
  return apiCore.post(`/core/dx/licenses/assign`, payload);
}
function unassignLicense(payload) {
  return apiCore.post(`/core/dx/licenses/unassign`, payload);
}
function updatePhoto(id, payload) {
  return apiCore.post(`/core/dx/licenses/${id}/logo`, payload);
}

/* Asset Type Template */
function getAssetTypeTemplate(id, options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get(`/core/dx/config/assetType/template/${id}`, applyOpts(optionsObj));
}

function importAssetTypeTemplateAssets(payload) {
  return apiCore.post(`/core/dx/assets/import`, payload);
}

function readImport(payload) {
  return apiCore.post(`/core/dx/assets/readImport`, payload);
}

function importAsset(payload) {
  return apiCore.post(`/core/dx/assets/importAsset`, payload);
}

/* Shipping Config */

function validateShippingCreds(payload) {
  return apiCore.post(`core/shipping/creds/validate`, payload);
}

function uploadShippingCreds(id, payload) {
  return apiCore.post(`core/shipping/creds/${id}`, payload);
}

export default repo;
