export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/pages/dashboard/main.vue'),
    meta: {
      public: false, // Allow access to even if not logged in
      onlyWhenLoggedOut: false,
      parent: 'dashboard',
      title: 'Homepage',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/login.vue'),
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
      title: 'Login',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/pages/logged_out.vue'),
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: false,
      title: 'Logout',
    },
  },
  {
    path: '/access-denied',
    name: 'access-denied',
    component: () => import('@/pages/access_denied.vue'),
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: false,
      title: 'Access Denied',
    },
  },
  {
    path: '/unexpected',
    name: 'unexpected',
    component: () => import('@/pages/unexpected.vue'),
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: false,
      title: 'Unexpected Error',
    },
  },
  {
    path: '/callback',
    name: 'callback',
    component: () => import('@/components/Callback.vue'),
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
      // title: "Callback", // No need to give this a title
    },
  },
  {
    path: '/sso',
    name: 'sso',
    component: () => import('@/components/SSO.vue'),
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: false,
      title: 'SSO',
    },
  },
  {
    path: '/documentation',
    name: 'documentation',
    component: () => import('@/pages/documentation.vue'),
    meta: {
      public: false, // Allow access to even if not logged in
      onlyWhenLoggedOut: false,
      parent: 'dashboard',
      title: 'Docs',
    },
  },
  {
    path: '/release-notes',
    name: 'release-notes',
    component: () => import('@/pages/releaseNotes.vue'),
    meta: {
      public: false, // Allow access to even if not logged in
      onlyWhenLoggedOut: false,
      parent: 'dashboard',
      title: 'Release Notes',
    },
  },
];
