import apiCore from '@/api/api.common';
// import { Cache } from 'axios-extensions';
import pick from 'lodash/orderBy';
import uniqBy from 'lodash/orderBy';
import shippingOptions from '@/api/core/lookups/shippingOptions';
import shippingOptionsInt from '@/api/core/lookups/shippingOptionsInt';
import shippingStates from '@/api/core/lookups/shippingStates';
import shippingProvinces from '@/api/core/lookups/shippingProvinces';
import shippingCountries from '@/api/core/lookups/shippingCountries';

// const FIVE_MINUTES = 1000 * 60 * 5;
// const CAPACITY = 100;
// const metricCache = new Cache({ ttl: FIVE_MINUTES, max: CAPACITY });
const dftOptions = { useCache: true };

const repo = {
  apiCore: apiCore,
  getOrders,
  getOrderById,
  getParts,
  getPartsWithOnHand,
  getPartCategories,
  getBom,
  getInventory,
  getInventoryByBom,
  putInventory,
  postInventoryFile: postInventoryFile,
  createOrder: createOrder,
  validateAddressUSPS: validateAddressUSPS,
  validateAddressUPS: validateAddressUPS,
  putPart: putPart,
  postPickPack: postPickPack,
  postReturnOrder: postReturnOrder,
  backOutRMA: backOutRMA,
  cancelReturnLabel: cancelReturnLabel,
  getBinLocs: getBinLocs,
  getBinLocsArray: getBinLocsArray,
  updatePhoto: updatePhoto,
  distinctShipTo: distinctShipTo,
  getCompanyLocations: getCompanyLocations,
  getInventoryLogs,
  dash: { getPartsSummaryInfo },
  download: {
    inventory: `${apiCore.baseUrl}public/import/inventory`,
  },
  inventory: {
    getBinLocs: getBinLocs,
    getTopLevelParts: getTopLevelParts,
    updateInventory: updateInventory,
    deleteInventory: deleteInventory,
  },
  lookups: {
    shippingOptions: shippingOptions,
    shippingOptionsInt: shippingOptionsInt,
    shippingStates: shippingStates,
    shippingCountries: shippingCountries,
    shippingProvinces: shippingProvinces,
  },
  bom: {
    getBOMAvailability: getBOMAvailability,
  },
  activeCompanies: [
    //{id: 292497, accountCode: 'MONTR', accountName: 'Montra'},
    { id: 336409, accountCode: 'PIXEL', accountName: 'Pixellot' },
    { id: 437503, accountCode: 'VIDSW', accountName: 'VidSwap' },
    { id: 450311, accountCode: 'MLB', accountName: 'MLB' },
    { id: 463793, accountCode: 'NBC', accountName: 'NBC - Sports Engine' },
    { id: 293950, accountCode: 'CANDI', accountName: 'Candid' },
    { id: 481045, accountCode: 'HTP', accountName: 'HTP' },
  ],
  config: {
    options: {
      get: getConfigOptions,
      post: postConfigOptions,
      patch: patchConfigOptions,
      delete: deleteConfigOptions,
    },
  },
  shipping: {
    getDomesticShippingOptions: getDomesticShippingOptions,
    getShippingRates: getShippingRates,
  },
  order: {
    availableSerials: getAvailableSerials,
    pickItemsForOrder: pickItemsForOrder,
  },
};

function applyOpts(options) {
  return Object.assign(options || dftOptions);
}
function getOrders(options) {
  return apiCore.get('/core/fulfillment/orders', applyOpts(options));
}
function getParts(options) {
  return apiCore.get('/core/fulfillment/partsv2', applyOpts(options));
}
function getPartsWithOnHand(options) {
  return apiCore.get('/core/fulfillment/partsWithOnHand', applyOpts(options));
}
/**
 * Get BOM for toplevel part, pass `partNumber:'xxxxx'`
 * @param {*} options
 */
function getBom(options) {
  return apiCore.get('/core/fulfillment/parts/bom', applyOpts(options));
}
function getPartCategories(options) {
  return apiCore.get('/core/fulfillment/parts/categories', applyOpts(options));
}
function getInventory(options) {
  return apiCore.get('/core/fulfillment/inventory', applyOpts(options));
}
function getInventoryByBom(options) {
  return apiCore.get('/core/fulfillment/inventory/bom', applyOpts(options));
}
function postPickPack(payload) {
  return apiCore.post('/core/fulfillment/inventory/pickpack', payload); //TODO create this backend api
}

function postReturnOrder(id, payload) {
  return apiCore.post(`/core/fulfillment/order/returnOrder/${id}`, payload);
}

function backOutRMA(id, payload) {
  return apiCore.post(`/core/bms/rma/backOutRMA/${id}`, payload);
}

function cancelReturnLabel(id, id2, payload) {
  return apiCore.post(`/core/fulfillment/order/cancelReturnOrder/${id}/${id2}`, payload);
}

function putInventory(payload) {
  let coll = [];
  if (payload) {
    if (Array.isArray(payload)) {
      coll = Array.concat(coll, payload);
    } else {
      coll.push(payload);
    }
    const updateColl = coll.map((m) => pick(m, ['warehouseLocationId', 'qty', 'partId']));
    return apiCore.put('/core/fulfillment/inventory', updateColl);
  }
}
/**
 * Payload should consist of FormData:
 * {accountId: companyId, method: replace | append, file: <fileName.xlsx>}
 * @param {*} payload
 */
function postInventoryFile(payload) {
  return apiCore.post('/core/fulfillment/import/inventory', payload);
}

function getOrderById(id, options) {
  const opt = Object.assign({ useCache: false }, options || {});

  return apiCore.get(`/core/fulfillment/orders/${id}`, opt).then((resp) => {
    if (resp && resp.status === 200) {
      // let orig = resp.data.Results;
      // if (orig && orig.notes) {
      //     orig.notes.forEach(remapTicketRefs);
      // }
      //resp.data.Results = Ticket.FromAPI(orig);
      /* orig.statusChanges = orig.statusChanges || [];
                                                    orig.priorityChanges = orig.priorityChanges || [];
                                                    orig.notes = orig.notes || [];
                                                    orig.attachments = orig.attachments || [];
                                                    orig.timeLogs = orig.timeLogs || [];
                                                    orig.rma = orig.rma || {}; */
    }
    return resp;
  });
}

function putPart(payload) {
  let coll = [];
  if (payload) {
    if (Array.isArray(payload)) {
      coll = Array.concat(coll, payload);
    } else {
      coll.push(payload);
    }
  }
  return apiCore.put('/core/fulfillment/parts', coll);
}
/****************************************************************
 * Dash
 */
function getPartsSummaryInfo(options) {
  return apiCore.get('/core/fulfillment/dash/partSummary', applyOpts(options));
}

/****************************************************************
    Inventory
*/
function getBinLocs(accountId, warehouseLocationId, partId) {
  const fltr = `companyId eq ${accountId} and warehouseLocationId eq ${warehouseLocationId} and partId eq ${partId}`;
  const req = {
    params: {
      $select: 'binLoc',
      $filter: fltr,
      $orderBy: 'binLoc asc',
      $top: 0,
      $skip: 0,
    },
  };
  return repo.getInventory(req).then((resp) => {
    return uniqBy(resp.data.Results, 'binLoc').map((m) => m.binLoc);
  });
}
function getBinLocsArray() {
  const req = {
    params: { $select: 'binLoc', $orderBy: 'binLoc asc', $top: 0, $skip: 0 },
  };
  return repo.getInventory(req).then((resp) => {
    return uniqBy(resp.data.Results, 'binLoc').map((m) => m.binLoc);
  });
}
async function getTopLevelParts(accountId, includeSalesKit = false) {
  let partItems = [];
  if (accountId) {
    const fltr = `companyId eq ${accountId} and isTopLevel eq true and isSalesKit eq ${includeSalesKit}`;
    const req = {
      params: {
        $select: 'id,name,description,partNumber',
        $filter: fltr,
        $orderBy: 'partNumber asc',
        $top: 0,
        $skip: 0,
      },
    };
    await repo.getParts(req).then((resp) => {
      partItems = resp.data.Results;
    });
  }
  return partItems;
}
/**
 * Find the maximum # of units we could build based on bom.qty and on-hand quantity
 * @param {*} partNumber
 */
async function getBOMAvailability(partNumber) {
  const response = {
    phantomBOM: [],
    availableToBuild: 0,
  };

  if (partNumber) {
    const req = { params: { partNumber: partNumber, $top: 500, $skip: 0 } };
    await getBom(req).then((resp) => {
      if (resp.data) {
        response.phantomBOM = resp.data.filter((f) => f.level > 0);
        const buildNeeds = response.phantomBOM.map((m) => {
          return m.ohq / m.total_qty;
        }); // phantomBOM assumes all available inventory can be used - adjust with selected rows of inventory
        response.availableToBuild = Math.min(...buildNeeds); // spread array of buildNeeds numbers to parameters for Math
      }
    });
  }
  return response;
}

function updatePhoto(id, payload) {
  return apiCore.post(`/core/fulfillment/parts/${id}/photo`, payload);
}

function distinctShipTo(payload) {
  return apiCore.get(`/core/fulfillment/order/distinctShipTo`, payload);
}

function getCompanyLocations(payload) {
  return apiCore.get(`/core/fulfillment/order/companyLocations`, payload);
}

function createOrder(payload) {
  return apiCore.post('/core/fulfillment/order/createOrder', payload);
}

// leaving in place for when we decide to revert back later on
function validateAddressUSPS(payload) {
  return apiCore.post(`/core/fulfillment/order/validate/addressUSPS`, payload);
}

// UPS validation is more permissive than USPS
function validateAddressUPS(payload) {
  return apiCore.post(`/core/fulfillment/order/validate/addressUPS`, payload);
}

/* Config Options */
function getConfigOptions(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/dx/config/options', applyOpts(optionsObj));
}
function postConfigOptions(payload) {
  return apiCore.post('/core/dx/config/options', payload);
}
function patchConfigOptions(id, payload) {
  return apiCore.patch(`/core/dx/config/options/${id}`, payload);
}
function deleteConfigOptions(id) {
  return apiCore.delete(`/core/dx/config/options/${id}`);
}

/* Shipping Methods (UPS & FedEx) */

function getDomesticShippingOptions(id) {
  return apiCore.get(`core/shipping/options/${id}`);
}
function getShippingRates(id, payload) {
  return apiCore.post(`/core/shipping/rates/${id}`, payload);
}

function updateInventory(payload) {
  return apiCore.post('/core/inventory/item', payload);
}

function deleteInventory(binLocId, partId, payload) {
  return apiCore.delete(`/core/inventory/${binLocId}/${partId}`, payload);
}

function getInventoryLogs(payload) {
  return apiCore.get(`/core/fulfillment/inventory/logs`, payload);
}

function getAvailableSerials(partId, payload) {
  return apiCore.get(`/core/fulfillment/order/availableSerials/part/${partId}`, payload);
}

function pickItemsForOrder(orderId, payload) {
  return apiCore.get(`/core/fulfillment/order/pickItemsForOrder/${orderId}`, payload);
}

export default repo;
