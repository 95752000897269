<template>
  <span class="clickable subtle" @click="helpQuery(k)">
    <span v-if="!hideLabel">{{ v }}</span> 
    <v-icon v-if="!hideIcon" class="ml-2 xx-small">fal fa-question-circle</v-icon>
  </span>
</template>

<script>
export default {
  name: 'HelpDisplay',
  props: {
    k: {
      type: String,
      required: false,
      default: '',
    },
    v: {
      type: String,
      required: false,
      default: '',
    },
    hideIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    helpQuery(k) {
      this.$store.commit('helpOn');
      this.$store.commit('helpQuery', k);
    },
  },
};
</script>
