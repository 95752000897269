<template>
  <v-navigation-drawer id="appDrawer" :model-value="drawer" width="200" class="aug-nav" augmented-ui="r-clip-y exe">
    <div class="logo-drawer d-flex align-center">
      <a class="mx-auto" @click="goHome()">
        <v-img
          :src="`${
            theme === 'dark' ? '/static/logo/montra-2021-horiz-white.png' : '/static/logo/montra-logo-2021-horiz.png'
          }`"
          title="Montra"
        />
      </a>
    </div>
    <div class="nav-wrapper" v-show="!loading">
      <v-list v-model:opened="openedGroups" density="compact" :key="cb">
        <div v-for="parentItem in menus" :key="parentItem.route_name" class="nav-item">
          <v-list-item
            v-if="parentItem.href"
            :prepend-icon="parentItem.icon"
            :title="parentItem.name"
            :to="parentItem.href"
            :active="inActive(parentItem.route_name)"
          />
          <v-list-group v-else :value="parentItem.route_name" collapse-icon="fa fa-caret-down" expand-icon="fa fa-caret-right">
            <template #activator="{ props, isOpen }">
              <v-list-item
                v-bind="props"
                :prepend-icon="parentItem.icon"
                :title="parentItem.name"
                :active="isOpen || inActive(parentItem.route_name)"
              />
            </template>
            <div v-for="child in parentItem.children" :key="child.id" class="nav-item child">
              <v-list-item
                v-if="isBarren(child.children)"
                :title="child.name"
                :to="child.href"
                :active="inActive(child.route_name)"
              />
              <v-list-group v-else :value="child.route_name" collapse-icon="fa fa-caret-down"
              expand-icon="fa fa-caret-right">
                <template #activator="{ props, isOpen }">
                  <v-list-item
                    v-bind="props"
                    :title="child.name"
                    :to="child.href"
                    :active="isOpen || inActive(child.route_name)"
                  />
                </template>
                <div v-for="grandchild in child.children" :key="grandchild.id" class="nav-item grandchild">
                  <v-list-item
                    v-if="isBarren(grandchild.children)"
                    :title="grandchild.name"
                    :to="grandchild.href"
                    :active="inActive(grandchild.route_name)"
                  />
                  <v-list-group
                    v-else
                    :value="grandchild.route_name"
                    collapse-icon="fa fa-caret-down"
                    expand-icon="fa fa-caret-right"
                  >
                    <template #activator="{ props, isOpen }">
                      <v-list-item
                        v-bind="props"
                        :title="grandchild.name"
                        :to="grandchild.href"
                        :active="isOpen || inActive(grandchild.route_name)"
                      />
                    </template>
                    <div
                      v-for="greatgrandchild in grandchild.children"
                      :key="greatgrandchild.id"
                      class="nav-item greatgrandchild"
                    >
                      <v-list-item
                        :title="greatgrandchild.name"
                        :to="greatgrandchild.href"
                        :active="inActive(greatgrandchild.route_name)"
                      />
                    </div>
                  </v-list-group>
                </div>
              </v-list-group>
            </div>
          </v-list-group>
        </div>
      </v-list>
    </div>
    <v-progress-linear v-if="loading" indeterminate color="primary" />
    <div class="d-flex nav-bottom">
      <div class="text-center footer-text pa-2 mx-auto">
        © {{ new Date().getFullYear() }} — <strong>Montra </strong>
        <router-link to="/release-notes" class="ml-1">
          <span class="ml-1">v{{ version }}</span>
        </router-link>
      </div>
    </div>
    <code ref="verInfo" style="visibility: hidden" />
  </v-navigation-drawer>
</template>
<script>
import store from '@/store/state';
import config from '@/common/config';
import { evalLevels } from '@/api/core/meta/userPermission.meta';

import { RepositoryFactory } from '@/api/repositoryFactory.js';
const repo = RepositoryFactory.get('eix');

export default {
  name: 'AppDrawer',
  components: {},
  props: {
    expanded: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    mini: false,
    tree: [],
    active: [],
    openedGroups: [],
    parent: null,
    menus: [],
    hrisState: false,
    cb: 0,
    loading: false,
  }),
  computed: {
    theme() {
      return localStorage.getItem('via-theme');
    },
    version() {
      return config.app.version;
    },
    user() {
      return this.$store.getters['account/user'];
    },
    drawer() {
      return this.$store.getters['drawer'];
    },
    menu_items() {
      const menu = [
        {
          name: 'Home',
          route_name: 'dashboard',
          id: 'home_dashboard',
          icon: 'fal fa-analytics',
          href: '/dashboard/main',
          children: [],
          hrefs: [],
          permissions: ['view_ticket', 'full_logistics', 'full_service_desk', 'view_asset'],
        },
        {
          name: 'People',
          icon: 'fal fa-users',
          route_name: 'peopleMenu',
          id: 'people_menu',
          hrefs: [],
          permissions: ['view_people', 'onboard', 'offboard', 'workforce', 'full_workforce', 'it_admin_workforce', 'admin_workforce', 'edit_people'],
          children: [
            {
              id: 'people_directory',
              name: 'Directory',
              href: '/people/ex/search',
              hrefs: ['/people/ex/profile/admin', '/people/ex/profile'],
              permissions: ['view_people', 'onboard', 'offboard', 'workforce', 'full_workforce', 'it_admin_workforce', 'admin_workforce', 'edit_people'],
              route_name: 'eixsearch',
            },
            {
              id: 'people_onboarding',
              name: 'Onboarding',
              href: '/people/onboarding',
              hrefs: [],
              permissions: ['onboard', 'full_workforce'],
              route_name: 'onboarding',
              children: [],
            },
            {
              id: 'people_offboarding',
              name: 'Offboarding',
              href: '/people/offboarding',
              hrefs: [],
              permissions: ['offboard', 'full_workforce'],
              route_name: 'offboarding',
              children: [],
            },
            // is tenant feature available AND is Administrator
            {
              id: 'people_messaging',
              name: 'Workforce Alerts',
              href: '/people/messaging',
              hrefs: ['/people/messaging_status'],
              permissions: ['workforce', 'full_workforce'],
              route_name: 'messaging',
              children: [],
            },
          ],
        },
        {
          name: 'Monitoring',
          icon: 'fal fa-user-chart',
          route_name: 'monitoringMenu',
          id: 'monitoring_menu',
          hrefs: [],
          permissions: ['view_asset', 'full_logistics'],
          children: [
            {
              id: 'monitoring_dashboard',
              name: 'Dashboard',
              href: '/dashboard/devices',
              hrefs: [],
              permissions: ['view_asset', 'full_logistics'],
              route_name: 'dash_devices',
            },
            {
              id: 'monitoring_assets',
              name: 'Monitored Assets',
              href: '/administration/tenant/setup/assets_monitoring',
              hrefs: [],
              permissions: ['view_asset', 'full_logistics'],
              route_name: 'tenant_setup_assets',
              children: [],
            },
          ],
        },
        {
          name: 'Fulfillment',
          icon: 'fal fa-box-alt',
          route_name: 'fulfilmentMenu',
          id: 'fulfilment_menu',
          hrefs: [],
          permissions: ['view_order', 'view_rma', 'full_logistics'],
          children: [
            {
              id: 'orders_fulfillment_order',
              name: 'Orders',
              href: '/fulfillment/list/orders',
              hrefs: [
                '/fulfillment/details/orderTicketDetail',
                '/fulfillment/details/orderEntry',
                '/fulfillment/details/orderEntryV2',
                '/fulfillment/details/orderEntryV3',
                '/fulfillment/details/orderReturn',
              ],
              permissions: ['view_order', 'full_logistics'],
              route_name: 'orders',
            },
            {
              id: 'returns_rmas_requests',
              name: 'RMAs',
              href: '/rma_requests/requests',
              hrefs: [
                '/rma_requests/admin/rmaDetailAdvanced',
                '/rma_requests/admin/rmaDetailReg',
                '/rma_requests/admin/rmaTicketDetail',
                '/rma_requests/admin/rmaReturnToStock',
              ],
              permissions: ['view_rma', 'full_logistics'],
              route_name: 'requests',
            },
            {
              id: 'returns_rmas_assets_dashboard',
              name: 'Returns Dashboard',
              href: '/dashboard/rma_inventory',
              hrefs: [],
              permissions: ['view_rma', 'full_logistics'],
              route_name: 'dash_rma_inventory',
            },
            {
              id: 'returns_rmas_status',
              name: 'Requests Dashboard',
              href: '/dashboard/rma_status',
              hrefs: [],
              permissions: ['view_rma', 'full_logistics'],
              route_name: 'dash_rma_status',
            },
          ],
        },
        {
          name: 'Inventory',
          icon: 'fal fa-inventory',
          route_name: 'logistics',
          id: 'logistics_menu',
          hrefs: [],
          permissions: ['view_item', 'view_inventory', 'full_logistics'],
          children: [
            {
              id: 'logistics_inventory',
              name: 'Inventory',
              href: '/logistics/inventory',
              hrefs: [],
              permissions: ['view_item', 'view_inventory', 'full_logistics'],
              route_name: 'logistics_inventory',
            },
            {
              id: 'logistics_binlocs',
              name: 'Bin Locations',
              href: '/logistics/binloc',
              hrefs: ['/logistics/binloc'],
              permissions: ['view_inventory', 'full_logistics'],
              route_name: 'binloc',
            },
            {
              id: 'logistics_warehouses',
              name: 'Warehouses',
              href: '/logistics/warehouse',
              hrefs: ['/logistics/warehouse'],
              permissions: ['view_inventory', 'full_logistics'],
              route_name: 'warehouse',
            },
            {
              id: 'logistics_logs',
              name: 'Logs',
              href: '/logistics/logs',
              hrefs: ['/logistics/logs'],
              permissions: ['view_inventory', 'full_logistics'],
              route_name: 'inventory_logs',
            },
          ],
        },
        {
          name: 'Information',
          icon: 'fal fa-tasks',
          route_name: 'ticketMenu',
          id: 'serviceDesk_menu',
          hrefs: [],
          permissions: ['view_ticket', 'view_asset', 'full_logistics', 'full_service_desk' ],
          children: [
            {
              id: 'serviceDesk_tickets',
              name: 'All Service Items',
              href: '/servicedesk/admin/tickets',
              hrefs: ['/servicedesk/admin/ticketDetail'],
              permissions: ['view_ticket', 'full_service_desk'],
              route_name: 'tickets',
              children: [],
            },
            {
              id: 'serviceDesk_assets',
              name: 'All Assets',
              href: '/administration/tenant/setup/assets',
              hrefs: ['/devices/admin/assetDetailNew'],
              permissions: ['view_asset', 'full_logistics'],
              route_name: 'tenant_setup_assets',
              children: [],
            },
          ],
        },
        {
          name: 'Administration',
          icon: 'fal fa-cogs',
          route_name: 'adminMenu',
          id: 'admin_menu',
          hrefs: [],
          permissions: ['full_tenant', 'admin_tenant', 'admin_workforce', 'full_workforce', 'admin_logistics', 'full_logistics', 'full_service_desk', 'admin_service_desk', 'it_admin_workforce','full_license_templates'],
          children: [
            {
              id: 'admin_global',
              name: 'Global',
              hrefs: [],
              permissions: ['super_admin','full_license_templates'],
              route_name: 'global',
              children: [
                {
                  id: 'admin_global_setup_tenants',
                  name: 'Tenants',
                  href: '/administration/global/setup/tenants',
                  hrefs: [],
                  featuresOrRoles: ['super_admin'],
                  route_name: 'global_setup_tenants',
                  children: [],
                },
                {
                  id: 'admin_global_setup_license_provider_templates',
                  name: 'License Templates',
                  href: '/administration/global/setup/license-provider-templates',
                  hrefs: [],
                  permissions: ['full_license_templates'],
                  route_name: 'global_setup_license_provider_templates',
                  children: [],
                },
                {
                  id: 'admin_global_setup_permissions',
                  name: 'Permissions',
                  href: '/administration/global/setup/permissions',
                  hrefs: [],
                  permissions: ['super_admin'],
                  route_name: 'global_setup_permissions',
                  children: [],
                },
              ],
            },
            {
              id: 'admin_tenant',
              name: 'Tenant',
              hrefs: [],
              permissions: ['admin_tenant', 'full_tenant'],
              route_name: 'tenant',
              children: [
                {
                  id: 'admin_tenant_setup_applications',
                  name: 'Applications',
                  href: '/administration/tenant/setup/applications',
                  hrefs: [],
                  permissions: ['admin_tenant', 'full_tenant'],
                  route_name: 'tenant_setup_applications',
                  children: [],
                },
                {
                  id: 'admin_tenant_setup_roles',
                  name: 'Roles',
                  href: '/administration/tenant/setup/roles',
                  hrefs: ['full_tenant'],
                  permissions: ['admin_tenant', 'full_tenant'],
                  route_name: 'tenant_setup_roles',
                  children: [],
                },
                {
                  id: 'admin_tenant_setup_via_users',
                  name: 'Via Users',
                  href: '/maintenance/admin/users',
                  hrefs: [],
                  permissions: ['admin_tenant', 'full_tenant'],
                  route_name: 'users',
                  children: [],
                },
                // {
                //   id: 'admin_tenant_setup_employees',
                //   name: 'Employees',
                //   href: '/maintenance/admin/employees',
                //   hrefs: [],
                //   permissions: ['admin_tenant', 'full_tenant'],
                //   route_name: 'employees',
                //   children: [],
                // },
                {
                  id: 'admin_tenant_setup_groups',
                  name: 'Groups',
                  href: '/administration/tenant/setup/groups',
                  hrefs: [],
                  permissions: ['admin_tenant', 'full_tenant'],
                  route_name: 'tenant_setup_groups',
                  children: [],
                },
                {
                  id: 'admin_tenant_setup_license_providers',
                  name: 'License Providers',
                  href: '/administration/tenant/setup/licenses',
                  hrefs: [],
                  permissions: ['admin_tenant', 'full_tenant'],
                  route_name: 'tenant_setup_license_providers',
                  children: [],
                },
                {
                  id: 'admin_tenant_setup_locations',
                  name: 'Locations',
                  href: '/administration/tenant/setup/locations',
                  hrefs: [],
                  permissions: ['admin_tenant', 'full_tenant'],
                  route_name: 'tenant_setup_locations',
                  children: [],
                },
                {
                  id: 'admin_tenant-shipping',
                  name: 'Shipping',
                  href: '/administration/tenant-shipping',
                  hrefs: [],
                  permissions: [ 'admin_tenant', 'full_tenant'],
                  route_name: 'tenant_shipping',
                  children: [],
                },
              ],
            },
            {
              id: 'admin_people_menu',
              name: 'People',
              route_name: 'adminPeopleMenu',
              hrefs: [],
              permissions: ['admin_workforce', 'full_workforce', 'it_admin_workforce'],
              children: [
                {
                  id: 'admin_people_admin_azure',
                  name: 'Microsoft 365',
                  href: '/people/ex/admin/setup:azure',
                  hrefs: [],
                  permissions: ['it_admin_workforce', 'full_workforce'],
                  route_name: 'people_admin_azure',
                  children: [],
                },
                {
                  id: 'admin_people_admin_hris',
                  name: 'HRIS',
                  href: '/people/ex/admin/setup:hris',
                  hrefs: [],
                  permissions: ['it_admin_workforce', 'full_workforce'],
                  route_name: 'people_admin_hris',
                  children: [],
                },
                {
                  id: 'admin_people_admin_offboarding',
                  name: 'Offboarding',
                  href: '/people/ex/admin/setup:offboarding',
                  hrefs: [],
                  permissions: ['it_admin_workforce', 'full_workforce'],
                  route_name: 'people_admin_offboarding',
                  children: [],
                },
                {
                  id: 'admin_people_admin_onboarding',
                  name: 'Onboarding',
                  href: '/people/ex/admin/setup:onboarding',
                  hrefs: [],
                  permissions: ['it_admin_workforce', 'full_workforce'],
                  route_name: 'people_admin_onboarding',
                  children: [],
                },
                {
                  id: 'admin_people_boarding_presets',
                  name: 'Presets',
                  href: '/people/ex/setup/presets',
                  hrefs: [],
                  permissions: ['admin_workforce', 'full_workforce', 'it_admin_workforce' ],
                  route_name: 'people_setup_presets',
                  children: [],
                },
                {
                  id: 'admin_tenant_setup_asset_configuration_asset_lists',
                  name: 'Asset Lists',
                  href: '/administration/tenant/setup/asset-configuration-asset-lists',
                  hrefs: [],
                  permissions: ['admin_workforce', 'full_workforce', 'it_admin_workforce'],
                  route_name: 'tenant_setup_asset_configuration_asset_lists',
                  children: [],
                },
                {
                  id: 'admin_people_customFields_menu',
                  name: 'Custom Fields',
                  route_name: 'adminPeopleCustomFieldspMenu',
                  hrefs: [],
                  permissions: ['admin_workforce', 'full_workforce', 'it_admin_workforce'],
                  children: [
                    {
                      id: 'admin_people_customFields_clients',
                      name: 'Clients',
                      href: '/people/ex/admin/admin:client',
                      hrefs: [],
                      permissions: ['admin_workforce', 'full_workforce', 'it_admin_workforce'],
                      route_name: 'people_setup_clients',
                      children: [],
                    },
                    // {
                    //   id: 'admin_people_customFields_collaboration',
                    //   name: 'Collaboration',
                    //   href: '/people/ex/admin/admin:collaboration',
                    //   hrefs: [],
                    //   permissions: [ 'People:Admin'],
                    //   route_name: 'people_setup_collaboration',
                    //   children: [],
                    // },
                    {
                      id: 'admin_people_customFields_departments',
                      name: 'Departments',
                      href: '/people/ex/admin/admin:department',
                      hrefs: [],
                      permissions: ['admin_workforce', 'full_workforce', 'it_admin_workforce'],
                      route_name: 'people_setup_departments',
                      children: [],
                    },
                    {
                      id: 'admin_people_customFields_employeeTypes',
                      name: 'Worker Types',
                      href: '/people/ex/admin/admin:employeeType',
                      hrefs: [],
                      permissions: ['admin_workforce', 'full_workforce', 'it_admin_workforce'],
                      route_name: 'people_setup_employeeTypes',
                      children: [],
                    },
                    {
                      id: 'admin_people_customFields_jobRoles',
                      name: 'Job Roles',
                      href: '/people/ex/admin/admin:jobRole',
                      hrefs: [],
                      permissions: ['admin_workforce', 'full_workforce', 'it_admin_workforce'],
                      route_name: 'people_setup_jobRoles',
                      children: [],
                    },
                    {
                      id: 'admin_people_customFields_jobTitles',
                      name: 'Job Titles',
                      href: '/people/ex/admin/admin:jobTitle',
                      hrefs: [],
                      permissions: ['admin_workforce', 'full_workforce', 'it_admin_workforce'],
                      route_name: 'people_setup_jobTitles',
                      children: [],
                    },
                    {
                      id: 'admin_people_customFields_officeLocations',
                      name: 'Office Locations',
                      href: '/people/ex/admin/admin:officeLocation',
                      hrefs: [],
                      permissions: ['admin_workforce', 'full_workforce', 'it_admin_workforce'],
                      route_name: 'people_setup_officeLocations',
                      children: [],
                    },
                  ],
                },
                {
                  id: 'admin_people_setup_logs',
                  name: 'People Logs',
                  href: '/people/ex/admin/setup:logs',
                  hrefs: [],
                  permissions: ['admin_workforce', 'full_workforce', 'it_admin_workforce'],
                  route_name: 'people_setup_logs',
                  children: [],
                },
              ],
            },
            {
              id: 'admin_assets_menu',
              name: 'Assets',
              route_name: 'adminAssetsMenu',
              hrefs: [],
              permissions: ['full_logistics', 'admin_logistics'],
              children: [
                {
                  id: 'admin_tenant_setup_assetLogs',
                  name: 'Asset Logs',
                  href: '/administration/tenant/setup/assets_logs',
                  hrefs: [],
                  permissions: ['full_logistics', 'admin_logistics'],
                  route_name: 'tenant_setup_asset_logs',
                  children: [],
                },
                {
                  id: 'admin_tenant_setup_asset_configuration_asset_types',
                  name: 'Asset Types',
                  href: '/administration/tenant/setup/asset-configuration-asset-types',
                  hrefs: [],
                  permissions: ['full_logistics', 'admin_logistics'],
                  route_name: 'tenant_setup_asset_configuration_asset_types',
                  children: [],
                },
                {
                  id: 'admin_tenant_setup_asset_configuration_categories',
                  name: 'Category Types',
                  href: '/administration/tenant/setup/asset-configuration-categories',
                  hrefs: [],
                  permissions: ['full_logistics', 'admin_logistics'],
                  route_name: 'tenant_setup_asset_configuration_categories',
                  children: [],
                },
                {
                  id: 'admin_tenant_setup_asset_configuration_asset_conditions',
                  name: 'Condition Types',
                  href: '/administration/tenant/setup/asset-configuration-asset-conditions',
                  hrefs: [],
                  permissions: ['full_logistics', 'admin_logistics'],
                  route_name: 'tenant_setup_asset_configuration_asset_conditions',
                  children: [],
                },
                {
                  id: 'admin_tenant_setup_asset_configuration_asset_states',
                  name: 'Status Types',
                  href: '/administration/tenant/setup/asset-configuration-asset-states',
                  hrefs: [],
                  permissions: ['full_logistics', 'admin_logistics'],
                  route_name: 'tenant_setup_asset_configuration_asset_states',
                  children: [],
                },
              ],
            },
            {
              id: 'admin_tickets_menu',
              name: 'Service Items',
              route_name: 'adminTicketMenu',
              hrefs: [],
              permissions: [ 'admin_service_desk', 'full_service_desk'],
              children: [
                {
                  id: 'admin_tickets_setup',
                  name: 'Setup',
                  href: '/servicedesk/admin/tickets/setup:tickets',
                  hrefs: [],
                  permissions: [ 'admin_service_desk', 'full_service_desk'],
                  route_name: 'admin_tickets_setup',
                  children: [],
                },
                {
                  id: 'admin_tickets_alerts',
                  name: 'Alerts',
                  href: '/servicedesk/admin/tickets/alerts',
                  hrefs: [],
                  permissions: ['admin_service_desk', 'full_service_desk'],
                  route_name: 'admin_tickets_alerts',
                  children: [],
                },
                {
                  id: 'admin_tickets_defaults',
                  name: 'Default Values',
                  href: '/servicedesk/admin/tickets/setup:ticketDefaults',
                  hrefs: [],
                  permissions: [ 'admin_service_desk', 'full_service_desk'],
                  route_name: 'admin_tickets_defaults',
                  children: [],
                },
                {
                  id: 'admin_tickets_mappings',
                  name: 'Field Mappings',
                  href: '/servicedesk/admin/tickets/setup:ticketMappings',
                  hrefs: [],
                  permissions: [ 'admin_service_desk', 'full_service_desk'],
                  route_name: 'admin_tickets_mappings',
                  children: [],
                },
                {
                  id: 'admin_tickets_setup_logs',
                  name: 'Service Item Logs',
                  href: '/servicedesk/admin/tickets/setup:ticketLogs',
                  hrefs: [],
                  permissions: [ 'admin_service_desk', 'full_service_desk'],
                  route_name: 'admin_tickets_setup_logs',
                  children: [],
                },
              ],
            },
          ],
        },
      ];
      if (this.hrisState) {
        // find the Administration index of menu variable
        const adminIndex = menu.findIndex((item) => item.name === 'Administration');
        // find the People index of menu variable
        const peopleIndex = menu[adminIndex]?.children.findIndex((item) => item.name === 'People');
        // find the HRIS index of menu variable
        const hrisIndex = menu[adminIndex]?.children[peopleIndex].children.findIndex((item) => item.name === 'HRIS');
        menu[adminIndex]?.children[peopleIndex]?.children.splice(hrisIndex + 1, 0, {
          id: 'admin_people_admin_hris_mappings',
          name: 'HRIS Sync',
          href: '/people/ex/admin/setup:hrisMappings',
          hrefs: [],
          permissions: [ 'admin_workforce', 'full_workforce', 'it_admin_workforce'],
          route_name: 'people_admin_hris_mappings',
          children: [],
        });
      }
      // Remove Workforce Alerts if that feature doesn't exist in the user's tenant
      if (!this.tenantIncludesFeature('Workforce')) {
        // console.log('Tenant doesnt have Workforce feature, removing nav item')
        const peopleIndex = menu.findIndex((item) => item.name === 'People');
        const messagingIndex = menu[peopleIndex].children.findIndex((item) => item.name === 'Workforce Alerts');
        menu[peopleIndex]?.children.splice(messagingIndex, 1);
      }
      return menu;
    },
  },
  async mounted() {
    this.loading = true;
    await this.makeNav();
    this.loading = false;
  },
  watch: {
    '$route.path': {
      handler() {
        // console.log(window.location.pathname);
        this.buildNav();
      },
    },
  },
  methods: {
    tenantIncludesFeature(feature) {
      let features = this.user?.tenant?.settings?.filter((ts) => {
        return ts.key === feature;
      });
      if(features) {
        return (/true/i).test(features[0]?.value) || false;
      } else {
        return false;
      }
    },
    inActive(ref) {
      let ret = 0;
      this.active.forEach((item) => {
        if (ref === item.route_name) {
          ret = 1;
        }
      });
      return ret > 0;
    },
    isBarren(array) {
      return array?.length === 0 || !array;
    },
    goHome() {
      this.$router.push(store.getters['account/defaultHref']);
    },
    updateActive(arr) {
      if (arr.length > 0) {
        if (this.$route.path !== arr[0].href) {
          // Dont push if already on the page
          this.parent = arr[0];
          if (!arr[0]?.hrefs.includes(this.$route.path)) {
            this.$router.push({ path: arr[0].href });
          }
        } else {
          this.parent = arr[0];
        }
      } else {
        if (this.parent?.hrefs.includes(this.$route.path)) {
          this.$router.push({ path: this.parent.href });
        } else {
          this.open[0].children.forEach((child) => {
            child.hrefs.forEach((href) => {
              if (href === this.$route.path) {
                this.$router.push({ path: child.href });
              }
            });
          });
        }
      }
    },
    getBaseUrl() {
      const arr = window.location.pathname.split('/');
      let final = '';
      arr.forEach((item, index) => {
        if (arr.length !== index + 1) {
          final = final + item + '/';
        }
      });
      return final.substring(0, final.length - 1);
    },
    async buildNav() {
      // console.log('Building Nav');
      this.active = [];
      this.open = [];
      const item = window.location.pathname;
      this.menus.forEach((parent) => {
        if (parent?.children) {
          if (parent.href === item || parent.hrefs.includes(item)) {
            this.active.push(parent);
          }
          parent.children.forEach((child) => {
            if (child.href === item || child.hrefs.includes(item) || this.getBaseUrl() === child.href) {
              this.active.push(child);
              this.open.push(child);
              this.open.push(parent);
            }
            if (child?.children) {
              child.children.forEach((grandchild) => {
                if (
                  grandchild.href === item ||
                  grandchild.hrefs.includes(item) ||
                  this.getBaseUrl() === grandchild.href
                ) {
                  this.active.push(grandchild);
                  this.open.push(grandchild);
                  this.open.push(child);
                  this.open.push(parent);
                }
                if (grandchild?.children) {
                  grandchild.children.forEach((greatgrandchild) => {
                    if (
                      greatgrandchild.href === item ||
                      greatgrandchild.hrefs.includes(item) ||
                      this.getBaseUrl() === greatgrandchild.href
                    ) {
                      this.active.push(greatgrandchild);
                      this.open.push(greatgrandchild);
                      this.open.push(grandchild);
                      this.open.push(child);
                      this.open.push(parent);
                    }
                  });
                }
              });
            }
          });
        }
      });
    },
    async getMenu() {
      // console.log('Getting Menu');
      const menu = this.loadMenu(this.user);
      await repo.config.options.get().then((resp) => {
        if (resp?.data?.Results?.length > 0) {
          resp.data.Results.map((m) => {
            if (m.meta.hide) {
              menu.filter((parent) => {
                if (parent?.children) {
                  parent.children.forEach((child) => {
                    // remove hidden children
                    if (child.href) {
                      if (child.href.includes(m.category)) {
                        parent.children.splice(parent.children.indexOf(child), 1);
                      }
                    }
                    if (child?.children) {
                      child.children.forEach((grandchild) => {
                        // remove hidden grandchildren
                        if (grandchild.href) {
                          if (grandchild?.href.includes(m.category)) {
                            child.children.splice(child.children.indexOf(grandchild), 1);
                          }
                        }
                        if (grandchild?.children) {
                          grandchild.children.forEach((greatgrandchild) => {
                            // remove hidden greatgrandchildren
                            if (greatgrandchild.href) {
                              if (greatgrandchild?.href.includes(m.category)) {
                                grandchild.children.splice(grandchild.children.indexOf(greatgrandchild), 1);
                              }
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
      this.menus = menu;
    },
    async isHRISEnabled() {
      return repo.config.options.get({ params: { $filter: "category eq 'setup:hris'" } }).then((resp) => {
        if (resp?.data?.Results?.length > 0) {
          this.hrisState =
            resp.data.Results[0]?.meta?.extension?.configuration?.hasValidated && resp.data.Results[0].enabled;
        }
      });
    },
    async makeNav() {
      // console.log('Making Nav');
      await this.isHRISEnabled().then(() => {
        this.getMenu().then(() => {
          this.buildNav();
        });
      });
    },
    loadMenu(user) {
      // console.log('Loading Menu');
      let clone = [];
      if (user.auth0Id) {
        clone = evalLevels(user, this.menu_items);
      }
      return clone;
    },
  },
};
</script>
