//import config from "@/common/config";
import { createApp } from 'vue';
import vuetify from '@/plugins/vuetify.js';
//import msalInstance from '@/plugins/msalPlugin';
import App from '@/App.vue';
import router from '@/router/router.js';
import store from '@/store/state.js';
import ApiService from '@/api/index';
// import LoggingService from '@/common/logging.service.js';
import { date, dateDiff } from '@/common/date.filter.js';
import { truncate, tailing, capitalize } from '@/common/string.filter.js';
import { percentage } from '@/common/percentage.filter.js';
import ErrorFilter from '@/common/error.filter.js';
import { byte, mbyte } from '@/common/bytes.filter.js';
import numFormat from '@/common/number.filter.js';
import Clipboard from 'v-clipboard';
import VuePapaParse from 'vue-papa-parse';
//import msalPlugin from "vue-msal-browser";
//import {LogLevel,ProtocolMode} from "@azure/msal-common"

const app = createApp(App);

// TODO: Init these for Vue 3!!
ApiService.init();
// LoggingService.init();

// this is deprecated in Vue 3
// Vue.config.productionTip = false;
// app.filter('date', date);
// app.filter('dateDiff', dateDiff);
// app.filter('error', ErrorFilter);
// app.filter('truncate', truncate);
// app.filter('tailing', tailing);
// app.filter('capitalize', capitalize);
// app.filter('percentage', percentage);
// app.filter('byte', byte);
// app.filter('mbyte', mbyte);
// app.filter('number', numFormat);

app.config.globalProperties.$filters = {
  date(filter, value) {
    return date(filter, value);
  },
  dateDiff(baseDate, options, value) {
    return dateDiff(baseDate, options, value);
  },
  error(value) {
    return ErrorFilter(value);
  },
  truncate(limit, trail, value) {
    return truncate(limit, trail, value);
  },
  tailing(value) {
    return tailing(value);
  },
  capitalize(value) {
    return capitalize(value);
  },
  percentage(value) {
    return percentage(value);
  },
  byte(value) {
    return byte(value);
  },
  mbyte(value) {
    return mbyte(value);
  },
  number(value) {
    return numFormat(value);
  },
};

//* ****************************
app.use(vuetify);
app.use(Clipboard);
app.use(VuePapaParse);
app.use(router);
app.use(store);
// app.use(VueAxios, axios);
// app.provide('axios', app.config.globalProperties.axios);
// app.use(
//   createAuth0({
//     domain: config.auth.AUTH0_DOMAIN,
//     clientId: config.auth.AUTH0_CLIENT_ID,
//     authorizationParams: {
//       redirect_uri: `${window.location.origin}/callback`,
//     },
//   }),
// );

app.mount('#app');
