import util from '@/util';
import axios from 'axios';
// import VueAxios from 'vue-axios';
import apiCore from '@/api/api.common';
import store from '@/store/state';
import config from '@/common/config';
//import createAuthRefreshInterceptor from 'axios-auth-refresh';
// import { cacheAdapterEnhancer } from 'axios-extensions';

const ApiService = {
  init() {
    const instance = axios.create({
      baseURL: config.api.uri,
      timeout: 30000,
      headers: { 'Cache-Control': 'no-cache' },
      // cache will be enabled by default
      // adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
      //   enabledByDefault: false,
      //   cacheFlag: 'useCache',
      // }),
    });
    instance._activeTokenRefresh = false;
    function showTimeoutError() {
      //  fake a timeout you maverick
      store.dispatch('alerts/addAlert', {
        alertText: 'You request has timed out. Try again, or refresh the page.',
        alertType: 'error',
        delay: 15000,
      });
    }

    function showRefreshingTokenWarning() {
      //  show a warning about refreshing the token
      store.dispatch('alerts/addAlert', {
        alertText:
          'Your credentials have expired, we are attempting to refresh your session. You may need to rerun your query.',
        alertType: 'warning',
        delay: 15000,
      });
    }

    // Add a request interceptor
    instance.interceptors.request.use(
      (config) => {
        // console.log('Using Interceptor', config);
        if (config?.data?.timeout || config?.params?.timeout) {
          showTimeoutError();
          return Promise.reject('Timeout enforced by development');
        }

        // console.log(store.state.account.auth);

        const accessToken = store?.state?.account?.auth?.accessToken;
        const idToken = store?.state?.account?.auth?.idToken;
        if (idToken) {
          // console.log('idToken exists');
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }

        const user = store.getters['account/user'];

        const impersonated = store.getters['account/isImpersonated'] || false;
        if (impersonated) {
          config.headers['impersonate'] = user.auth0Id;
        }

        // countdown timer until token will expire...  is it true though?
        // let timeUntil401 = util.getDifferenceInSeconds(store.state.account.auth.expiresAt)
        // console.log(timeUntil401)

        config.__retryCount = 0;
        config.__isRetryRequest = false;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const status = error.response?.status === 401; // unauthorized
        const timeout = error.response?.status === 500;

        if (timeout) {
          if (error.response?.data?.Errors || error.response?.data?.errors) {
            return Promise.reject(error);
          } else {
            showTimeoutError();
          }
        } else if (status && !error.config.__isRetryRequest && error.config.__retryCount < 1) {
          if (status) {
            console.log(error);
          }
          // Increase the retry count, not quite sure if this is doing anything
          error.config.__retryCount = error.config.__retryCount || 0;
          error.config.__retryCount += 1;
          error.config.__isRetryRequest = true;

          // find the authService in question
          const authService = store.getters['account/authService'];

          // call refreshToken to get new Token
          await authService
            .refreshToken()
            .then((resp) => {
              if (resp) {
                // console.log(JSON.stringify(resp));
                // let them know we are re-authorizing them after a 401
                showRefreshingTokenWarning();
                if (error.config.url === '/force401') {
                  return Promise.reject(error);
                }

                // Create new promise to handle exponential backoff
                const backoff = new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                  }, error.config.retryDelay || 4);
                });

                // Return the promise in which recalls axios to retry the request
                return backoff.then(() => {
                  error.config.__isRetryRequest = true;
                  return axios(error.config);
                });
              } else {
                return Promise.reject(error);
              }
            })
            .catch((error) => {
              return Promise.reject(error);
            });
        }
        // Reject with error
        return Promise.reject(error);
      },
    );

    apiCore.CancelToken = axios.CancelToken;
    apiCore.axios = instance;
  },

  apiCore,

  /**
   * On 401 response (01 Unauthorized ) and account.auth.expires at is pastdue, log the user out
   * @param {*} error
   * @returns
   */
  isTokenExpired(error) {
    // logic to determine if the error is due to JWT token expired returns a boolean value
    if (error && error.response && error.response.status == 401) {
      const expiresTime = store.state.account.auth.expiresAt; // capture the expire date from last authentication
      const hasTimeRemaining = util.isFutureDate(expiresTime);
      return !hasTimeRemaining; // if that date is still in the future, we should still be ok.
    } else {
      return false; // default, something else has has triggered error, let axios handle as needed.
    }
  },
};

export default ApiService;
