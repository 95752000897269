import { AuthO365Service } from './autho365.service';
import { Auth0Service } from './auth0.service';
import store from '@/store/state';
import ApiService from '@/api/index';
import config from '@/common/config';

// import Vue from 'vue';
class AuthenticationService {
  static signInType = {
    popup: 'loginPopup',
    redirect: 'loginRedirect',
  };

  //auth0 = new Auth0Service();
  auth0 = null;
  authO365 = null;
  constructor() {
    this.auth0 = new Auth0Service();
    this.authO365 = new AuthO365Service();
    this.authO365.init();
  }

  async refreshToken() {
    const idpUser = store.getters['account/ssoToken'];
    if (idpUser) {
      const newSSOToken = await this.authO365.refreshToken(idpUser);
      if (newSSOToken) {
        // Vue.$log.debug(JSON.stringify(newSSOToken, null, 2));
        return await this.authO365.processUpdateToken(newSSOToken);
      }
    } else {
      return this.auth0.refreshToken();
    }
  }

  async loginUser(target) {
    const idpUser = store.getters['account/ssoToken'];

    if (target == 'sso' || idpUser) {
      // return dispatch('loginSSOUser2', redirectName || "people/eix");
      return await this.authO365.signIn();
    } else {
      //return dispatch('loginAuth0User', redirectName);
      return await this.auth0.login();
    }
  }

  async logoutUser() {
    const accounts = this.authO365.msal.getAllAccounts();
    console.log(accounts, window.location.origin, config.app.sitePath);
    if (accounts.length > 0) {
      return this.authO365.signOut();
    } else {
      console.log('logging out of auth0');
      store.dispatch('account/clearUser');
      await this.auth0.logout({
        logoutParams: {
          returnTo: `${window.location.origin}`,
        },
      });
    }
  }

  async isAuthenticationValid(routeTarget) {
    const routeTargetURL = typeof routeTarget === 'string' ? routeTarget : '/';
    let valid = false;
    try {
      const isAuthenticated = store.getters['account/isAuthenticated']();
      if (isAuthenticated) {
        const test = await ApiService.apiCore.get(`/auth/validate?to=${routeTargetURL}`).catch((err) => {
          if (err.message.includes('401')) {
            valid = false;
          }

          return false;
        });
        if (test && test.status == 200) {
          valid = true;
        }
      }
    } catch (err) {
      valid = false;
    }

    return valid;
  }
}

export { AuthenticationService };
