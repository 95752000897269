<template>
  <div v-if="shouldShowOptIn" justify="center">
    <v-dialog v-model="dialog" persistent retain-focus max-width="750px">
      <v-card>
        <v-card-title>
          <span class="main-header">Welcome to Via!</span>
        </v-card-title>
        <v-card-text >
          <v-alert type="warning" class="mb-6">
            Our User Terms have changed
          </v-alert>
          <div v-if="tenantHasTOS">
            <h3>Accept Terms of Service (Required)</h3>
            <div class="d-flex justify-space-between align-top mt-4">
              <div style="width: 50%">
                <v-checkbox v-model="acceptTOSSelect" label="I have reviewed and agree to the Terms of Service and Privacy Policy." hide-details />
              </div>
              <div class="ml-4" style="width: 50%">
                <v-list class="tinywell">
                  <v-list-item v-for="link in links" density="compact" prepend-icon="fal fa-link x-small" :key="link" class="small-link mr-6">
                    <v-list-item-title><a class="subtle" :href="link.link" target="_blank">{{ link.title }}</a></v-list-item-title>
                  </v-list-item>
                </v-list>
              </div>
              
            </div>            
          </div>
          <div v-if="tenantHasSMS">
            <h3>Allow SMS Messages (Optional)</h3>
            <div class="d-flex align-top mt-4">
              <div style="width: 50%">
                <v-checkbox v-model="allowSMSSelect" :label="`I agree to receive SMS alerts from ${user.tenant.description} relating to my job while employed. `" hide-details />
              </div>
              <div class="well ml-6" style="overflow: auto; max-height: 150px; width: 50%">
                <p class="text-body-2">{{user.tenant.description}} uses Via to send SMS-based alerts to you at the mobile and work numbers in your employee profile.  These will typically be urgent notices regarding IT incidents, weather alerts, or other business matters.</p>
                <p class="text-body-2 mt-3">Message frequency varies.  Message and data rates may apply.</p>
                <p class="text-body-2 mt-3">You may reply STOP to opt-out at any time.  You may also update your preference in your "My Profile" page within Via.</p>
                <p class="text-body-2 mt-3">You may view the Via user terms of service and privacy policy at <a href="https://montra.io/legal/utos" class="subtle">https://montra.io/legal/utos</a></p>
              </div>
            </div>            
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn variant="text" @click="disagree()"> Disagree </v-btn>
          <v-btn color="info" :disabled="!acceptTOSSelect" variant="text" @click="accept()"> Agree </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import store from "@/store/state";
import { RepositoryFactory } from '@/api/repositoryFactory';
// import util from '@/util';
const adminRepo = RepositoryFactory.get('admin');

export default {
  name: 'CookieConsent',
  data() {
    return {
      dialog: true,
      links: [
        { title: 'User Terms of Service', link: 'https://montra.io/legal/utos' },
        { title: 'Privacy Policy', link: 'https://montra.io/legal/privacy' },
      ],
      allowSMSSelect: true,
      acceptTOSSelect: true,
    };
  },
  computed: {
    user() {
      return this.$store.getters['account/user'];
    },
    userSettings() {
      return this.user?.settings || [];
    },
    tenantSettings() {
      return this.user?.tenant?.settings || [];
    },
    acceptTOS() {
      let tos = this.userSettings.filter((ts) => {
        return ts.key === 'TOS_OptIn';
      });
      if(tos) {
        return (/true/i).test(tos[0]?.value) || false;
      } else {
        return null;
      }
      
    },
    acceptSMS() {
      let sms = this.userSettings.filter((ts) => {
        return ts.key === 'SMS_OptIn';
      });
      if(sms) {
        return (/true/i).test(sms[0]?.value) || false;
      } else {
        return null;
      }
    },
    tenantHasTOS() {
      let tos = this.tenantSettings.filter((ts) => {
        return ts.key === 'TOS_OptIn';
      });
      return (/true/i).test(tos[0]?.value) || false;
    },
    tenantHasSMS() {
      let sms = this.tenantSettings.filter((ts) => {
        return ts.key === 'SMS_OptIn';
      });
      return (/true/i).test(sms[0]?.value) || false;
    },
    /**
     * Determines if the opt-in modal should be shown to the user.
     * 
     * The opt-in modal is shown under the following conditions:
     * 1. The user is logged in (not on the login page and has a user ID).
     * 2. The tenant has Terms of Service (TOS) that the user has not accepted yet or has not responded to.
     * 3. The tenant has SMS terms that the user has not accepted yet.
     * 
     * @returns {Boolean} True if the user is logged in and needs to accept either TOS or SMS terms, false otherwise.
     */
    shouldShowOptIn() {
      const notLoginPage = this.$route.path !== '/sso' && this.$route.path !== '/callback';
      const isLoggedIn = notLoginPage && this.user.id;
      const needsToAcceptTOS = this.tenantHasTOS && (!this.acceptTOS || this.acceptTOS === null);
      const needsToAcceptSMS = this.tenantHasSMS && this.acceptSMS === null;

      return isLoggedIn && (needsToAcceptTOS || needsToAcceptSMS);
    },
  },
  created() {
    if (this.shouldShowOptIn) {
      this.dialog = true;
    }
  },
  methods: {
    async setUserMeta() {
      await adminRepo.auth.updateUserMeta([
        { key: 'TOS_OptIn', value: `${this.acceptTOSSelect}` },
        { key: 'SMS_OptIn', value: `${this.allowSMSSelect}` },
      ]).then((resp) => {
        if (resp && resp.status === 200 && resp.data.Results) {
          if(this.user?.settings.length > 0){
            this.user.settings = this.user.settings.filter(s => !['TOS_OptIn', 'SMS_OptIn'].includes(s.key));
          }
          this.user.settings.push(...resp.data.Results);
          this.$store.dispatch('account/setUser', this.user);
        }
      });
    },
    async disagree() {
      this.user.settings.forEach((k) => {
        if (k.key === 'TOS_OptIn') {
          k.value = `false"`;
        }
        if (k.key === 'SMS_OptIn') {
          k.value = `false`;
        }
      });
      this.$store.dispatch('account/setUser', this.user);
      await adminRepo.auth.updateUserMeta([
        { key: 'TOS_OptIn', value: `false` },
        { key: 'SMS_OptIn', value: `false` },
      ]);
      this.$store.dispatch('account/logoutUser');
    },
    accept() {
      this.setUserMeta(true)
        .finally(() => {
          this.dialog = false;
        });
    },
  },
};
</script>