<template>
  <v-row v-if="!hasConsented" justify="center">
    <v-dialog v-model="dialog" persistent retain-focus max-width="600">
      <v-card>
        <v-card-title class="main-header"> Montra Cookie Requirements </v-card-title>
        <v-card-text>
          <v-progress-circular v-if="busy" :size="50" color="primary" indeterminate />
          <p>
            We use <b>strictkly</b> necessary and <b>functional</b> cookies to offer you a smoother and more personalized
            experience. A combination of local storage and cookies are essential for running our web sites and are the
            key to providing you a seamless experience. You can navigate uninterrupted e.g. by remembering choices
            you’ve made and by preserving authentication to the secure portions of our domain.
          </p>
          <p>
            These cookies DO NOT gather information about you that could be used for marketing or remembering where
            you've been on the internet. We DO NOT use any advertising or third-party related tracking cookies.
          </p>
          <p>
            Unfortunately, without these settings we simpley cannot provide the services required for a useful
            experience. Disagreeing will log you out and return to our login page.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn variant="text" @click="disagree()"> Disagree </v-btn>
          <v-btn color="info" variant="text" @click="accept()"> Agree </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
// import store from "@/store/state";
import { RepositoryFactory } from '@/api/repositoryFactory';
// import util from '@/util';
const adminRepo = RepositoryFactory.get('admin');

export default {
  name: 'CookieConsent',
  data() {
    return {
      isOpen: true,
      dialog: true,
      busy: false,
    };
  },
  computed: {
    user() {
      const user = this.$store.getters['account/user'];
      return user;
    },
    hasConsented() {
      // const consented = util.get(this.user,'userMeta.cookieConsent', false) == true;
      //console.log("Has consented: " + consented);
      //return consented;
      /**  Util we have sign-off on the legal text, leave as positive consent for now */
      return true;
    },
  },
  created() {
    if (!this.hasConsented) {
      this.isOpen = true;
    }
  },
  methods: {
    async setVisited(status) {
      this.user.userMeta.cookieConsent = status;
      this.$store.dispatch('account/setUser', this.user);
      return await adminRepo.auth.updateUserMeta(); // call api to set
    },
    disagree() {
      this.busy = true;
      console.log('user logout');
      this.setVisited(false).finally(() => {
        this.busy = false;
        this.isOpen = false;
        this.$store.dispatch('account/logoutUser');
      });
      ///  Logout method from AppToolbar.vue
    },
    accept() {
      this.busy = true;
      this.setVisited(true)
        .then(() => {
          // this.$emit('accept');
        })
        .finally(() => {
          this.busy = false;
          this.isOpen = false;
        });
    },
  },
};
</script>
<style scoped></style>
