<template>
  <v-navigation-drawer v-model="help" location="right" disable-resize-watcher>
    <v-card rounded="0">
      <v-card-title>
        <span><HelpDisplay k="help" v="Help"></HelpDisplay></span>
        <div class="flex-grow-1" />
        <v-btn variant="text" @click="toggleHelp">
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <splitpanes
          horizontal
          :push-other-panes="false"
          class="default-theme saved-filter-height"
          @resize="changeSubPaneSizes($event)"
          style="height: calc(100vh - 117px)"
        >
          <pane class="overflow-y pa-3" :size="filter1size" min-size="5">
            <v-text-field
              v-model="search"
              label="Search"
              placeholder="Search"
              hide-details
              prepend-inner-icon="fal fa-search"
              density="compact"
              variant="outlined"
              class="mb-2"
              clearable
            />
            <div v-if="foundKeywords?.length > 0">
              Found Keywords:
              <div class="overflow-small-well" style="max-height: 75px" v-if="!loading">
                <div class="clickable" v-for="keyword in foundKeywords" :class="{ 'selectedSearchQuery': keyword.title === searchResults.title }" @click="getMore(keyword.path)">
                  <span variant="text">{{ keyword.title }}</span>
                </div>
              </div>
              <v-progress-linear v-if="loading" indeterminate color="primary" />
            </div>
            <div v-else>
              No results found.
            </div>
          </pane>
          <pane class="overflow-y pa-3" :size="filter2size" min-size="5" style="overflow: auto">
            Search Results
            <div class="modal-title">{{ searchResults.title }}</div>
            <p v-html="searchResults.content"></p>
          </pane>
          <pane class="overflow-y pa-3" :size="filter3size" min-size="5">
            <div v-if="relatedKeywords.length > 0">
              Tags:
              <div class="overflow-small-well" style="max-height: 75px" v-if="!loading">
                <div v-for="keyword in relatedKeywords">
                  <span class="clickable" variant="text" @click="search = keyword.tag">{{ keyword.tag }}</span>
                </div>
              </div>
            </div>
            
          </pane>
        </splitpanes> 
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import store from '@/store/state';
import HelpDisplay from '@/components/HelpDisplay.vue';

import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

import { RepositoryFactory } from '@/api/repositoryFactory.js';
const admin = RepositoryFactory.get('admin');

export default {
  name: 'AppHelp',
  components: {
    Splitpanes,
    Pane,
    HelpDisplay,
  },
  data: () => ({
    helpVal: false,
    search: '',
    filter1size: 25,
    filter2size: 50,
    filter3size: 15,
    foundKeywords: [],
    searchResults: {
      title: null,
      description: null,      
    },
    relatedKeywords: [],
    loading: false,
  }),
  watch: {
    search: function (val) {
      if (val?.length > 2) {
        this.getKeywords(val);
      } else {
        this.foundKeywords = [];
        this.relatedKeywords = [];
        this.searchResults = { title: 'No information found.' };
      }
    },
    helpQuery: function (val) {
      this.search = val;
    },
  },
  computed: {
    help: {
      get() {
        return store.state.help || false;
      },
    },
    helpQuery: {
      get() {
        return store.state.helpQuery || '';
      },
    },
  },
  mounted() {
    this.helpVal = this.help;
  },
  methods: {
    replaceRelativeLinks(text) {
      const baseUrl = 'https://wiki.montra.io';
      const regex = /src="(\/[^"]+)"/g;
      return text.replace(regex, `src="${baseUrl}$1"`);
    },
    toggleHelp() {
      store.commit('toggleHelp');
    },
    changeSubPaneSizes(newSize) {
      this.filter1size = newSize[0].size;
      this.filter2size = newSize[1].size;
      this.filter3size = newSize[2].size;
    },
    async getKeywords(keyword) {
      this.loading = true;
      await admin.wikijs.searchWikiJSPage(keyword).then(async (resp) => {
        if (resp.data?.Results) {
          this.foundKeywords = resp.data?.Results.filter((result) => result.path.includes('home/documentation'));
          this.loading = false;
          this.getMore(this.foundKeywords[0]?.path);
        }
      });
    },
    async getMore(path) {
      this.loading = true;
      await admin.wikijs.getWikiPageByPath({ path: path }).then(async (resp) => {
        if (resp.data?.Results) {
          this.searchResults = resp.data?.Results;
          this.searchResults.content = this.replaceRelativeLinks(resp.data?.Results?.content);
          this.relatedKeywords = resp.data?.Results?.tags;
        } else {
          this.searchResults = { title: 'No information found.' };
        }
        this.loading = false;
      });
    },
  },
};
</script>