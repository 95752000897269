import axios from 'axios';
import { compareVersions } from 'compare-versions';
import * as packageMeta from '../../package.json';

const useVersionCheck = true;

// inspiration: https://medium.com/@codemonk/automatic-reload-of-client-after-deploying-in-vue-js-91c120f85f0e

export const refreshPageMixin = {
  data() {
    // If dev environment, get hash from /dist/js/version.json
    if (window.location.hostname == 'localhost') {
      this.overwriteHashForDevEnv();
    }
    return {
      currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}', // this will get replaced on build with actual file hash
      hashChanged: false,
      newHash: '',
      currentVersion: import.meta.env.VITE_VUE_APP_VERSION, //appVersion
      newVersion: '0.0.1',
    };
  },
  methods: {
    // only needed for hash capture when running local
    async overwriteHashForDevEnv() {
      const fileResponse = await axios.get(`/version.json?t=${new Date().getTime()}`).catch((error) => {
        // console.log('error getting version.json', error);
        this.currentHash = Math.random().toString(36).substring(2, 15);
        this.currentVersion = packageMeta.version;
      });
      if (fileResponse && fileResponse.data && fileResponse.data.hash) {
        this.currentHash = fileResponse.data.hash;
        this.currentVersion = packageMeta.version;
      }
    },
    initVersionCheck(frequency = 1000 * 60 * 15) {
      // every 15 minutes - change to every 4 hours or something after testing
      this.checkVersion();
      setInterval(() => {
        this.checkVersion();
      }, frequency);
    },
    async checkVersion() {
      try {
        const fileResponse = await axios.get(`/version.json?t=${new Date().getTime()}`);

        this.newHash = fileResponse.data.hash;
        this.newVersion = fileResponse.data.version;

        this.hashChanged = useVersionCheck
          ? this.hasVersionChanged(this.currentVersion, this.newVersion)
          : this.hasHashChanged(this.currentHash, this.newHash);
      } catch (error) {
        this.loading = false;
        if (!error.response) {
          this.errorStatus = 'Error: Network Error';
        } else {
          this.errorStatus = error.response?.data.message || error.message;
        }
      }
    },

    hasVersionChanged(current, newVer) {
      /****
             *  compareVersions('11.1.1', '10.0.0'); //  1
                compareVersions('10.0.0', '10.0.0'); //  0
                compareVersions('10.0.0', '11.1.1'); // -1
             */
      return compareVersions(current, newVer) === -1; // if current is older than server == -1
    },

    hasHashChanged(currentHash, newHash) {
      if (window.location.hostname == 'localhost') {
        return false;
      }

      if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
        return true;
      }

      return currentHash !== newHash;
    },

    reloadApp() {
      this.currentHash = this.newHash;
      this.currentVersion = this.newVersion;
      /* FYI So a boolean parameter is not part of the current specification for location.reload(true) — 
                   and in fact has never been part of any specification for location.reload() ever published. 
            */
      window.location.reload();
    },

    closeUpdateModal() {
      this.hashChanged = false;
    },
  },
};
